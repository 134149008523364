import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from '../services/api.service';
import { RoleService } from '../services/role.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
  public mailForm = new FormGroup({
    senderName: new FormControl("", Validators.required),
    senderEmail: new FormControl("", [Validators.required, Validators.email]),
    subject: new FormControl("", Validators.required),
    mailContent: new FormControl("", Validators.required)
  });

  private mailIsSending = false;

  constructor(
    private apiService: ApiService,
    private roleService: RoleService,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
  }

  public sendMessage(): void {
    if (!this.mailIsSending) {
      this.mailIsSending = true;

      this.apiService.sendMail({
        name: this.mailForm.controls["senderName"].value,
        email: this.mailForm.controls["senderEmail"].value,
        subject: this.mailForm.controls["subject"].value,
        text: this.mailForm.controls["mailContent"].value
      }).subscribe(() => {
        this.mailIsSending = false;
        this.snackBar.open('Votre mail a été envoyé', 'OK', {
          duration: 5000
        });
      }, (error) => {
        this.mailIsSending = false;
        console.log(error);
        this.snackBar.open('Un problème est survenu lors de l\'envoi du mail', 'OK', {
          duration: 5000
        });
      });
    } else {
      this.snackBar.open('Le mail est en train d\'être envoyé', 'OK', {
        duration: 5000
      });
    }
  }

  public get isManager(): boolean {
    return this.roleService.isRoleAllowed(['manager']);
  }

  public get isTrainer(): boolean {
    return this.roleService.isRoleAllowed(['trainer']);
  }
}
