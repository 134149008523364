import { ApiService } from './../../services/api.service';
import { Group, User } from './../class.component';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-workgroup-create',
  templateUrl: './workgroup-create.component.html',
  styleUrls: ['./workgroup-create.component.scss']
})
export class WorkgroupCreateComponent implements OnInit {
  public groupForm: FormGroup = new FormGroup({
    groupName: new FormControl('', Validators.required)
  });

  public group: Group | null = null;
  public userList: User[] = [];
  private selectedUsers: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<WorkgroupCreateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Record<string, any>,
  ) {
    this.group = data.group;
    this.userList = data.userList;

    this.selectedUsers = (this.group?.members as string[]).slice();
  }

  ngOnInit(): void {
    this.groupForm.setValue({
      groupName: this.group?.name
    });


  }

  public selectUser(event: MatCheckboxChange): void {
    if (event.checked) {
      this.selectedUsers.push(event.source.value);
    } else {
      this.selectedUsers.splice(this.selectedUsers.indexOf(event.source.value), 1);
    }
  }

  public isUserSelected(userId: string): boolean {
    return this.selectedUsers.indexOf(userId) > -1;
  }

  public saveGroup(): void {
    if (this.group) {
      this.group.name = this.groupForm.controls['groupName'].value;
      this.group.members = this.selectedUsers;

      this.dialogRef.close();
    }
  }
}
