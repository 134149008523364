<mat-drawer-container>
    <mat-drawer mode="side" [opened]="!this.isUrlPublic()">
        <span class="vertical-align">
            <app-navigation-item icon="library_books" link="/games-list" text="Gérer mes parties"></app-navigation-item>
            <app-navigation-item icon="school" link="/classes-list" text="Gérer mes classes" *ngIf="roleService.isRoleAllowed(['trainer'])"></app-navigation-item>
            <app-navigation-item icon="format_list_numbered" link="/tournaments-list" text="Liste des tournois"></app-navigation-item>
            <app-navigation-item icon="co_present" link="/profile" text="Gérer mon compte"></app-navigation-item>
            <app-navigation-item icon="videogame_asset" link="/download" text="Télécharger le jeu" *ngIf="roleService.isRoleAllowed(['trainer', 'learner', 'manager'])"></app-navigation-item>
            <app-navigation-item icon="help_center" link="/help" text="Aide"></app-navigation-item>
            <app-navigation-item icon="logout" link="/logout" text="Déconnexion"></app-navigation-item>
        </span>
    </mat-drawer>

    <mat-drawer-content>
        <app-content></app-content>
    </mat-drawer-content>
</mat-drawer-container>
