<div class="button-wrapper" *ngIf="roleService.isRoleAllowed(['trainer', 'manager'])">
    <button mat-raised-button color="accent" (click)="goToGameCreation()">
        <mat-icon>add</mat-icon>
        <span>Créer partie</span>
    </button>
</div>

<mat-accordion multi>
    <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
            <mat-panel-title>Parties en cours</mat-panel-title>
        </mat-expansion-panel-header>
        <mat-list role="list">
            <mat-list-item role="listitem" *ngFor="let game of currentGames">
                <span>{{ game.name }} - Finit le {{ game.endDate | date:'dd/MM/yy à H:mm' }} 
                    - Partie {{ gameVisibility(game) }}
                    <ng-template [ngIf]="isMyGamePrivate(game)">
                         - {{ game.code }} 
                         <mat-icon class="content-copy" [cdkCopyToClipboard]="game.code" (click)="notifyCopy()">content_copy</mat-icon>
                    </ng-template>
                </span>
                <span class="right-aligned-button">
                    <button mat-flat-button color="accent" (click)="goToGameDetails(game._id)">Détails</button>
                    <button mat-raised-button color="warn" style="margin-left: 10px;" *ngIf="isGameOwnedByUser(game)" (click)="deleteGame(game._id)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </span>
                <mat-divider *ngIf="game !== currentGames[currentGames.length - 1]"></mat-divider>
            </mat-list-item>
        </mat-list>
    </mat-expansion-panel>

    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>Parties terminées</mat-panel-title>
        </mat-expansion-panel-header>
        <mat-list role="list">
            <mat-list-item role="listitem" *ngFor="let game of finishedGames">
                <span>{{ game.name }} - Finit le {{ game.endDate | date:'dd/MM/yy à H' }}h 
                    - Partie {{ gameVisibility(game) }}
                    <ng-template [ngIf]="isMyGamePrivate(game)">
                         - {{ game.code }} 
                         <mat-icon class="content-copy" [cdkCopyToClipboard]="game.code" (click)="notifyCopy()">content_copy</mat-icon>
                    </ng-template>
                </span>
                <span class="right-aligned-button">
                    <button mat-flat-button color="accent" (click)="goToGameDetails(game._id)">Détails</button>
                    <button mat-raised-button color="warn" style="margin-left: 10px;" *ngIf="isGameOwnedByUser(game)" (click)="deleteGame(game._id)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </span>
                <mat-divider *ngIf="game !== finishedGames[finishedGames.length - 1]"></mat-divider>
            </mat-list-item>
        </mat-list>
    </mat-expansion-panel>
</mat-accordion>
