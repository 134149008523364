import { ClassService } from './class/class.service';
import { NavigationService } from './layout/components/navigation/navigation.service';
import { SharedMaterialModule } from './shared/shared-material.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FlexLayoutModule } from '@angular/flex-layout';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutComponent } from './layout/layout.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent} from './home/home.component';
import { HomeDialogComponent } from './home/home.component';
import { HomeNewsDialogComponent } from './home/home.component';
import { LogoutComponent } from './logout/logout.component';
import { HeaderComponent } from './layout/components/header/header.component';
import { NavigationComponent } from './layout/components/navigation/navigation.component';
import { ContentComponent } from './layout/components/content/content.component';
import { GameDetailsComponent } from './game-details/game-details.component';
import { GamesListComponent } from './games-list/games-list.component';
import { ClassesListComponent } from './classes-list/classes-list.component';
import { ApiService } from './services/api.service';

import { AuthService } from './services/auth.service';
import { AuthInterceptor } from './services/auth.interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { GameCreateComponent } from './game-create/game-create.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { ProfileManagementComponent } from './profile-management/profile-management.component';
import { RoleService } from './services/role.service';
import { RegisterComponent } from './register/register.component';
import { TournamentsListComponent } from './tournaments-list/tournaments-list.component';
import { DownloadGameComponent } from './download-game/download-game.component';
import { NavigationItemComponent } from './layout/components/navigation/components/navigation-item/navigation-item.component';
import { HelpComponent } from './help/help.component';
import { ClassComponent } from './class/class.component';
import { WorkgroupCreateComponent } from './class/workgroup-create/workgroup-create.component';
import { ClassCreateComponent } from './class/class-create/class-create.component';
import { PopupComponent } from './popup/popup.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { MatGridListModule } from "@angular/material/grid-list";
import { Pipe, PipeTransform } from "@angular/core";
import { DialogWrongRoleComponent } from './login/dialog-wrong-role/dialog-wrong-role.component';
import { DialogRoleRequestComponent } from './register/dialog-role-request/dialog-role-request.component';
import { MatInputModule } from "@angular/material/input";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MomentDateAdapter } from '@angular/material-moment-adapter';


@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    LoginComponent,
    HomeComponent,
    HomeDialogComponent,
    HomeNewsDialogComponent,
    HeaderComponent,
    NavigationComponent,
    ClassesListComponent,
    ContentComponent,
    GameCreateComponent,
    GameDetailsComponent,
    GamesListComponent,
    LogoutComponent,
    RegisterComponent,
    TournamentsListComponent,
    ProfileManagementComponent,
    DownloadGameComponent,
    NavigationItemComponent,
    HelpComponent,
    ClassComponent,
    WorkgroupCreateComponent,
    ClassCreateComponent,
    PopupComponent,
    ResetPasswordComponent,
    DialogWrongRoleComponent,
    DialogRoleRequestComponent,
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        SharedMaterialModule,
        FormsModule,
        ReactiveFormsModule,
        MatNativeDateModule,
        FlexLayoutModule,
        MatGridListModule,
        MatInputModule,
        MatDatepickerModule,
        MatFormFieldModule,
    ],
  providers: [
    ApiService,
    NavigationService,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    ApiService,
    RoleService,
    ClassService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
