<div class="center">
  <div fxLayout fxFill fxLayoutAlign="space-around center">
    <mat-card>
      <mat-card-content>
        <h1>Roots of Tomorrow</h1>
        <h2>Le nouveau jeu gratuit pour apprendre l'agroécologie</h2>
        <button class="side-text mid-button" mat-flat-button color="accent" (click)="OnClickGoLink('https://gamabilis.com/jeux/roots-of-tomorrow/')">Tout savoir sur le jeu</button>
        <hr class="hr-margin">
        <h2>Bienvenue sur la console ! Choisissez votre accès :</h2>
        <br>
        <div>
          <div class="containter">
            <div class="column">
              <img class="side-image character-image" src="/assets/images/Gaelle.png" />
              <div class="vertical">
                <h3 class="side-text">
                  - Paramétrer les parties pour vos élèves.<br>
                  - Suivre leur évolution.<br>
                  - Gérer votre classe.<br>
                </h3>
                <button class="side-text role-button" mat-flat-button style="background-color: orange;" (click)="ngOnClickAccess('trainer')">Accès Enseignant</button>
              </div>
            </div>
            <div class="column">
              <img class="side-image character-image" src="/assets/images/Miguel.png" />
              <div class="vertical">
                <h3 class="side-text">
                  - Organiser et partager des tournois<br> en privé ou en public.<br>
                  - Suivre les scores en temps réel.<br>
                </h3>
                <button class="side-text role-button" mat-flat-button style="background-color: green;" (click)="ngOnClickAccess('manager')">Accès Tournoi</button>
              </div>
            </div>
          </div>
          <div class="containter">
            <div class="column">
              <img class="side-image character-image" src="/assets/images/Thomas.png" />
              <div class="vertical">
                <h3 class="side-text">
                  - Rejoindre des parties créées par vos profs.<br>
                  - Consulter et rejoindre des tournois.<br>
                  - Comparer vos scores et télécharger le jeu sans passer par steam.<br>
                </h3>
                <button class="side-text role-button" mat-flat-button style="background-color: royalblue;" (click)="ngOnClickAccess('learner')">Accès Joueur</button>
              </div>
            </div>
            <div class="column">
              <h3 class="side-text container-text">
                Un même compte peut avoir 3 accès, il suffit de vous inscrire aux 3 avec la même adresse email.
              </h3>
            </div>
          </div>
        </div>
        <div>
          <hr class="hr-margin">
          <h2 padding="10%">Téléchargement du jeu :</h2>
          <div class="progress-bar">
            <mat-progress-bar mode="buffer" color="accent"  [value]="progressValue" [bufferValue]="progressValue" *ngIf="!canDownload"></mat-progress-bar>
          </div>
        </div>
        <mat-grid-list  *ngIf="!this.isMobileSize" class="grid-list" cols="4" rowHeight="4:1">
          <mat-grid-tile class="mat-grid-tile-download">
            <button class="side-text download-button" mat-flat-button style="background-color: #424242;" (click)="OnClickGoLink('https://store.steampowered.com/app/1605430/Roots_of_Tomorrow/?l=french')">Version Steam PC/Mac</button>
          </mat-grid-tile>
          <mat-grid-tile class="mat-grid-tile-download">
            <button class="side-text download-button" mat-flat-button style="background-color: #616161;" (click)="OnClickGoLink('https://play.google.com/store/apps/details?id=com.gamabilis.rootsoftomorrow')">Version Mobile Android</button>
          </mat-grid-tile>
          <mat-grid-tile class="mat-grid-tile-download">
            <button class="side-text download-button" mat-flat-button style="background-color: #616161;" (click)="OnClickGoLink('https://apps.apple.com/fr/app/roots-of-tomorrow/id1592140112')">Version mobile IOS</button>
          </mat-grid-tile>
          <mat-grid-tile class="mat-grid-tile-download">
            <button class="side-text download-button" mat-flat-button style="background-color: #757575;" (click)="openDialogDownload('PC','win')">Version hors ligne PC</button>
          </mat-grid-tile>
          <!--
          <mat-grid-tile class="mat-grid-tile-download">
            <button class="side-text download-button" mat-flat-button style="background-color: #9e9e9e;" (click)="openDialogDownload('Mac','mac')">Version hors ligne Mac</button>
          </mat-grid-tile>
          -->
        </mat-grid-list>
        <div  *ngIf="this.isMobileSize">
          <button class=" download-button" mat-flat-button style="background-color: #424242;" (click)="OnClickGoLink('https://store.steampowered.com/app/1605430/Roots_of_Tomorrow/?l=french')">Version Steam en ligne</button>
          <button class=" download-button" mat-flat-button style="background-color: #616161;" (click)="OnClickGoLink('https://play.google.com/store/apps/details?id=com.gamabilis.rootsoftomorrow')">Version Mobile Android</button>
          <button class=" download-button" mat-flat-button style="background-color: #616161;" (click)="OnClickGoLink('https://apps.apple.com/fr/app/roots-of-tomorrow/id1592140112')">Version mobile IOS</button>
          <button class=" download-button" mat-flat-button style="background-color: #757575;" (click)="openDialogDownload('PC','win')">Version hors ligne PC</button>
          <!--<button class=" download-button" mat-flat-button style="background-color: #9e9e9e;" (click)="openDialogDownload('Mac','mac')">Version hors ligne Mac</button>-->
        </div>
        <hr class="hr-margin">
        <h2 padding="10%">Les actus récentes :</h2>
        <mat-grid-list class="grid-list" cols="{{this.getNewsColMobile()}}" rowHeight="3:1">
          <mat-grid-tile *ngFor="let number of [0,1,2,3,4,5,6,7,8]">
            <button class="side-text news-button" mat-flat-button style="background-color: #2d261a;" *ngIf="ifArrayIsFull()" (click)="openDialogNews(number)">
              <div class="news-title-button">
                <p class="news-title-title">{{arraySteamNews[number].titleNews}}</p>
                <p class="news-title-date">{{intToDate(arraySteamNews[number].date)}}</p>
              </div>
            </button>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-card-content>
    </mat-card>
  </div>
</div>
