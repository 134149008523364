import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '../services/auth.service';
import { RoleService, Role } from '../services/role.service';
import { DialogWrongRoleComponent } from './dialog-wrong-role/dialog-wrong-role.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loginForm = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required)
  });

  public loginTypeTitle = [
    {id: 'learner', title: 'Accès joueur', subtitle: ''},
    {id: 'trainer', title: 'Accès enseignant', subtitle: 'Accessible avec une licence spécifique, pour toute information : contact@gamabilis.com'},
    {id: 'manager', title: 'Accès tournoi', subtitle: 'Accessible avec une licence spécifique, pour toute information : contact@gamabilis.com'}
  ];

  public showPassword = false;
  public loginType = 'learner';

  private rememberLogin = 'false';

  public connectionError = '';

  constructor(private authService: AuthService, private roleService: RoleService, private router: Router,
    private route: ActivatedRoute, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params) => {
      if (params.keys.includes('role')) {
        if (params.get('role')) {
          this.loginType = params.get('role')!;

          if (this.loginTypeTitle.findIndex((value) => { return value.id === this.loginType }) === -1) {
            this.loginType = 'learner';
          }
        } else {
          this.loginType = 'learner';
        }
      } else {
        this.loginType = 'learner';
      }

      localStorage.setItem('loginType', this.loginType);

      if (this.roleService.isRoleAllowed([this.loginType])) {
        this.router.navigate(['/games-list']);
      }
    });
  }

  public togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  public getLoginTypeTitle(): string {
    const loginTypeHeader = this.loginTypeTitle.find(elt => elt.id === this.loginType);

    if (loginTypeHeader) {
      return loginTypeHeader.title;
    } else {
      return '';
    }
  }

  public getLoginTypeSubtitle(): string {
    const loginTypeHeader = this.loginTypeTitle.find(elt => elt.id === this.loginType);

    if (loginTypeHeader) {
      return loginTypeHeader.subtitle;
    } else {
      return '';
    }
  }

  public rememberMeValue(event: MatCheckboxChange) {
    this.rememberLogin = event.checked ? 'true' : 'false';
  }

  public getUsernameErrorMessage() {
    if (this.loginForm.controls['username'].hasError('required')) {
      return 'Vous devez entrer un nom d\'utilisateur';
    }

    return '';
  }

  public getPasswordErrorMessage() {
    if (this.loginForm.controls['password'].hasError('required')) {
      return 'Vous devez entrer un mot de passe';
    }

    return this.loginForm.controls['password'].hasError('minlength') ? 'Le mot de passe doit contenir au moins 6 caractères' : '';
  }

  public onSubmit() {
    this.authService.login(this.loginForm.value).subscribe(() => {
      this.connectionError = '';
      localStorage.setItem('remember_me', this.rememberLogin);
      let correctRole = false;
      this.roleService.fetchUserInfos((user) => {
        if (user.role.constructor == Array) {
          user.role.forEach(roleItem => {
            if (roleItem.name === this.loginType) {
              correctRole = true;
            }
          });
        } else {
          if ((<Role>user.role).name === this.loginType) {
            correctRole = true;
          }
        }

        if (correctRole) {
          this.router.navigate([this.authService.redirectUrl]);
        } else {
          this.dialog.open(DialogWrongRoleComponent, {data: {loginType: this.loginType}});
        }
      });
    }, (err: any) => {
      if (err.status === 503) {
        this.connectionError = 'Mauvais nom d\'utilisateur et/ou mot de passe';
      } else {
        this.connectionError = 'Erreur lors de la connexion';
      }

      console.log(this.connectionError);
    });
  }
}
