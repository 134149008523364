import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-dialog-role-request',
  templateUrl: './dialog-role-request.component.html',
  styleUrls: ['./dialog-role-request.component.scss']
})
export class DialogRoleRequestComponent implements OnInit {

  constructor(private authService: AuthService, private router: Router, public dialogRef: MatDialog) { }

  ngOnInit(): void {
  }

  public connect() {
    this.dialogRef.closeAll();
    this.router.navigate([this.authService.redirectUrl]);
  }
}
