import { NavigationService } from './../layout/components/navigation/navigation.service';
import { HttpClient } from '@angular/common/http';
import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from '../services/api.service';
import { DatePipe } from '@angular/common';

export interface PlayedGames {
  user: {
    username: string
  },
  currentTurn: number,
  maxTurns: number,
  timePlayed: number,
  status: string,
  score: number,
  code: string
}

export interface Ratings {
  environmental: number,
  economical: number,
  social: number,
  sanitary: number,
  global: number,
}

export interface GameProperties {
  scenario: number,
  turnLength: number,
  researchGoal: string,
  objectiveGoal: string,
  ratings: Ratings,
}

export interface Game {
  name: string,
  startDate: Date,
  endDate: Date,
  properties: GameProperties,
}

@Component({
  selector: 'app-game-details',
  templateUrl: './game-details.component.html',
  styleUrls: ['./game-details.component.scss']
})
export class GameDetailsComponent implements AfterViewInit {
  public game: Game = {name: "", startDate: new Date(), endDate: new Date(), properties: {
      scenario: 0, turnLength: 0, researchGoal: "0", objectiveGoal: "0", ratings: {
        environmental: 0, economical: 0, social: 0, sanitary: 0, global: 0
      }
    }
  };

  public gameResearchName = "";
  public gameObjectiveName = "";
  public gameScenarioName = "";

  public playedGamesList?: MatTableDataSource<PlayedGames> = undefined;

  public tableColumns = ['username', 'currentTurn', 'timePlayed', 'status', 'score'];

  private researchJsonURL = "assets/technos.json";
  private objectivesJsonURL = "assets/choices.json";

  private scenarios = [
    {id: 1, value: 'Bretagne - Porcin'},
    {id: 2, value: 'PACA - Ovins'},
    {id: 3, value: 'Grand Est - Bovins'},
    {id: 4, value: 'Normandie - Vaches laitières'},
    {id: 6, value: 'Grand Est - Grandes Cultures'},
    {id: 7, value: 'Grand Est - Cheptel Mixtes'},
    {id: 8, value: 'AURA - Bovins Allaitants'},
  ];

  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private apiService: ApiService,
    private http: HttpClient,
    private navigationService: NavigationService,
    private route: ActivatedRoute,
  ) { }

  ngAfterViewInit(): void {
    const gameId = this.route.snapshot.paramMap.get('gameId');
    const datePipe: DatePipe = new DatePipe('en-US');

    if (gameId) {
      this.apiService.getGameFromId(gameId).subscribe((game: any) => {
        this.game = game;

        let title = this.game.name + " - Fini le " + datePipe.transform(this.game.endDate, 'dd/MM/yy à h') + "h"
        this.navigationService.setTitle(title);

        if (this.game.properties.researchGoal !== "0") {
          this.getJSON(this.researchJsonURL).subscribe((data) => {
            this.gameResearchName = this.getValueFromJson(this.game.properties.researchGoal, data);
          });
        }

        if (this.game.properties.objectiveGoal !== "0") {
          this.getJSON(this.objectivesJsonURL).subscribe((data) => {
            this.gameObjectiveName = this.getValueFromJson(this.game.properties.objectiveGoal, data);
          });
        }

        this.scenarios.forEach((scenario) => {
          if (scenario.id === this.game.properties.scenario) {
            this.gameScenarioName = scenario.value;
          }
        });

        const maxTurns = this.computeMaxTurns(this.game.properties.turnLength);

        this.apiService.getPlayedGamesFromGame(gameId).subscribe((playedGames: any) => {
          this.playedGamesList = new MatTableDataSource(playedGames);
          this.playedGamesList.sort = this.sort;

          this.playedGamesList.data.forEach((playedGame) => {
            playedGame.maxTurns = maxTurns;
            playedGame.score = Number(playedGame.score.toFixed(2));
          });
        }, (err: any) => {
          console.log('Error : ' + err);
        });
      }, (err: any) => {
        console.log('Error : ' + err);
      });
    } else {
      console.log('No gameId found');
    }
  }

  ngOnDestroy(): void {
    this.navigationService.clearTitle();
  }

  public numberToTime(time: number): string {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time / 60) % 60);
    const seconds = Math.floor(time % 60);

    return this.formatNumberTwoDigits(hours) + ':' + this.formatNumberTwoDigits(minutes) + ':' + this.formatNumberTwoDigits(seconds);
  }

  private formatNumberTwoDigits(numberToFormat: number): string {
    return (numberToFormat < 10 ? '0' + numberToFormat : numberToFormat.toString());
  }

  private getJSON(jsonURL: string): Observable<any> {
    return this.http.get(jsonURL);
  }

  private getValueFromJson(key: string, jsonData: any): string {
    const scenarioData = jsonData.dictionaryList[this.game.properties.scenario - 1];

    for (let i = 0; i < scenarioData.m_keys.length; i++) {
      if (scenarioData.m_keys[i] === key) {
        return scenarioData.m_values[i];
      }
    }

    return "";
  }

  private computeMaxTurns(turnLength: number): number {
    let startDate = new Date();
    const endDate = new Date(startDate).setFullYear(startDate.getFullYear() + 10);

    let turnCounter = 0;

    while (startDate.valueOf() < endDate.valueOf()) {
      turnCounter++;
      startDate.setDate(startDate.getDate() + turnLength * 7);

      // Just in case
      if (turnCounter > 1000) {
        break;
      }
    }

    return turnCounter;
  }
}
