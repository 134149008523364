<div class="button-wrapper" *ngIf="roleService.isRoleAllowed(['trainer'])">
    <button mat-raised-button color="accent" [disabled]="creditsCount == 0" (click)="goToClassCreation()">
        <mat-icon>add</mat-icon>
        <span>Créer une classe ({{ creditsCount }} {{ creditsCount == 1 ? "restante" : "restantes" }})</span>
    </button>
</div>

<table mat-table [dataSource]="classesList" matSort>
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nom de la classe </th>
        <td mat-cell *matCellDef="let class"> {{ class.name }} </td>
    </ng-container>

    <ng-container matColumnDef="schoolYear">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Année scolaire </th>
        <td mat-cell *matCellDef="let class"> {{ class.attributes.schoolYear }} </td>
    </ng-container>

    <ng-container matColumnDef="members">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre d'élèves </th>
        <td mat-cell *matCellDef="let class"> {{ class.members.length }} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
    <tr mat-row (click)="editClass(row)" *matRowDef="let row; columns: tableColumns"></tr>
</table>