import { ApiService } from './../services/api.service';
import { Component, DebugElement, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Game, User } from '../games-list/games-list.component';

export interface Organization {
  _id: string,
  owners: string[],
  name: string,
  logo: string,
}

@Component({
  selector: 'app-tournaments-list',
  templateUrl: './tournaments-list.component.html',
  styleUrls: ['./tournaments-list.component.scss']
})
export class TournamentsListComponent implements OnInit {
  public currentGames: Game[] = [];
  public finishedGames: Game[] = [];

  public displayedColumns: string[] = [
    'name', 'type', 'players', 'endDate', 'actions'
  ];

  public gameType = 'current';

  public organizations: Organization[] = [];

  constructor(
    private apiService: ApiService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.apiService.getTournamentGamesFromUser().subscribe((gameList: Game[]) => {
      let currentGames: Game[] = [];
      let finishedGames: Game[] = [];

      let processedOrganizations: string[] = [];

      gameList.forEach((game: Game) => {
        if (new Date(game.endDate) > new Date(Date.now())) {
          currentGames.push(game);
        } else {
          finishedGames.push(game);
        }

        game.owners.forEach((owner: User) => {
          if (!processedOrganizations.includes(owner.organization)) {
            processedOrganizations.push(owner.organization);

            this.apiService.getOrganizationFromId(owner.organization).subscribe((organization: Organization) => {
              if (organization) {
                this.organizations.push(organization);
                game.orgLogo = organization.logo;

                // Update all games made by organization
                this.updateGameListLogos(organization._id, organization.logo);
              }
            });
          }
        });
      });

      this.currentGames = currentGames;
      this.finishedGames = finishedGames;
    }, (err: any) => {
      console.log('Error : ' + err);
    })
  }

  public getGameList(): Game[] {
    if (this.gameType === 'current') {
      return this.currentGames;
    } else {
      return this.finishedGames;
    }
  }

  public goToGameDetails(gameId: string) {
    this.router.navigate(['/game-details/' + gameId]);
  }

  private updateGameListLogos(organizationId: string, organizationLogo: string): void {
    this.currentGames.forEach((game: Game) => {
      this.updateGameLogo(game, organizationId, organizationLogo);
    });

    this.finishedGames.forEach((game: Game) => {
      this.updateGameLogo(game, organizationId, organizationLogo);
    });
  }

  private updateGameLogo(game: Game, organizationId: string, organizationLogo: string): void {
    if (game.owners !== null && game.owners.length > 0) {
      if (game.owners[0].organization !== organizationId) {
        return;
      }

      game.orgLogo = organizationLogo;
    }
  }
}
