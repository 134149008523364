<div *ngIf="data.userIsConnected; else elseBlock">
  <h1 mat-dialog-title>Cette version hors ligne {{data.name}} permet de jouer à Roots of Tomorrow
    sans passer par une plate-forme extérieure.</h1>
  <h1 mat-dialog-title>Pour la maintenir à jour, veuillez revenir sur ce site pour télécharger la dernière
    version.</h1>
  <div mat-dialog-actions>
    <button style="margin: auto; font-size: 1.1vw;" mat-flat-button color="accent" (click)="onDownloadClick()">Télécharger</button>
  </div>
</div>
<ng-template #elseBlock>
  <h1 mat-dialog-title>Pour télécharger la version du jeu en standalone, il faut absolument que vous soyez connecté.</h1>
  <h1 mat-dialog-title>Retournez à l'accueil pour vous connecter en fonction de votre rôle.</h1>
  <div mat-dialog-actions>
    <button style="margin: auto; font-size: 1.1vw;" mat-flat-button color="accent" (click)="onReturnClick()">Retour</button>
  </div>
</ng-template>



