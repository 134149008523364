<div class="download-wrapper" fxLayout fxFill fxLayoutAlign="space-around center">
    <mat-card>
        <h1>Télécharger le jeu sans passer par Steam</h1>
        
        <img src="/assets/images/Icon_Game.png" />

        <div class="horizontal">
            <button mat-flat-button color="accent" (click)="downloadGame('mac')" [disabled]="!canDownload">
                <mat-icon svgIcon="apple"></mat-icon>
                macOS
            </button>
            <button mat-flat-button color="accent" (click)="downloadGame('win')" [disabled]="!canDownload">
                <mat-icon svgIcon="microsoft"></mat-icon>
                Windows
            </button>
        </div>

        <mat-progress-bar mode="buffer" color="accent" [value]="progressValue" [bufferValue]="progressValue" *ngIf="!canDownload"></mat-progress-bar>
    </mat-card>
</div>