import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'roots-front';

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    matIconRegistry.addSvgIcon(
      'apple',
      domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/Apple_logo_black.svg')
    );
    matIconRegistry.addSvgIcon(
      'microsoft',
      domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/Windows_logo_-_2012.svg')
    );
  }
}
