<div class="button-wrapper">
    <mat-button-toggle-group name="game" [(ngModel)]="gameType">
        <mat-button-toggle checked value="current">Tournois en cours</mat-button-toggle>
        <mat-button-toggle value="finished">Tournois terminés</mat-button-toggle>
    </mat-button-toggle-group>
</div>

<table mat-table [dataSource]="getGameList()">
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Nom</th>
        <td mat-cell *matCellDef="let game" class="horizontal-align">
            <p class="text-vertical-align">{{ game.name }}</p>
            <img *ngIf="game.orgLogo" src={{game.orgLogo}}>
        </td>
    </ng-container>

    <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Type</th>
        <td mat-cell *matCellDef="let game">{{ game.visibility }}</td>
    </ng-container>

    <ng-container matColumnDef="players">
        <th mat-header-cell *matHeaderCellDef>Joueur·euse·s</th>
        <td mat-cell *matCellDef="let game">{{ game.group.members.length }}{{ game.group.maxMembers ? "/" + game.group.maxMembers : "" }}</td>
    </ng-container>

    <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef>Date de fin</th>
        <td mat-cell *matCellDef="let game">{{ game.endDate | date:'dd/MM/yy à H:mm' }}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let game">
            <button mat-flat-button color="accent" (click)="goToGameDetails(game._id)">Classement</button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
