import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../services/api.service';
import { RoleService } from '../services/role.service';

export interface PatchUser {
  username: string,
  email: string,
  oldPassword: string,
  newPassword: string,
}

@Component({
  selector: 'app-profile-management',
  templateUrl: './profile-management.component.html',
  styleUrls: ['./profile-management.component.scss']
})
export class ProfileManagementComponent implements OnInit {
  public profileForm = new FormGroup({
    username: new FormControl(undefined),
    email: new FormControl(undefined, Validators.email),
    oldPassword: new FormControl("", Validators.required),
    newPassword: new FormControl(undefined, Validators.minLength(6))
  });

  public errorMessage = "";
  public successMessage = "";

  constructor(private apiService: ApiService, private roleService: RoleService) { }

  ngOnInit(): void {
  }

  public patchProfile(): void {
    this.successMessage = "";
    this.errorMessage = "";

    const userObject: PatchUser = {
      username: this.profileForm.controls["username"].value ? this.profileForm.controls["username"].value : undefined,
      email: this.profileForm.controls["email"].value ? this.profileForm.controls["email"].value : undefined,
      oldPassword: this.profileForm.controls["oldPassword"].value,
      newPassword: this.profileForm.controls["newPassword"].value ? this.profileForm.controls["newPassword"].value : undefined,
    };

    this.apiService.updateUserInfos(userObject).subscribe((user) => {
      this.roleService.fetchUserInfos();
      this.successMessage = "Les modifications ont été enregistrées";
    }, (error) => {
      if (error.message === "Wrong password") {
        this.errorMessage = "Le mot de passe est erroné";
      } else {
        this.errorMessage = "Une erreur s'est produite au niveau du serveur";
      }
    });
  }
}
