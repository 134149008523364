import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ClassService {
  private schoolYearList: string[] = [];

  constructor() { }

  public get schoolYears() {
    if (this.schoolYearList.length === 0) {
      this.getPossibleSchoolYears(0, 1);
    }

    return this.schoolYearList;
  }

  private getPossibleSchoolYears(schoolYearsBefore: number, schoolYearsAfter: number): void {
    const currentMonth = new Date().getMonth() + 1;
    let currentSchoolYear = new Date().getFullYear();

    if (currentMonth < 7) {
      currentSchoolYear--;
    }

    let schoolYear = currentSchoolYear - schoolYearsBefore;

    while (schoolYear <= currentSchoolYear + schoolYearsAfter) {
      const formattedSchoolYear = schoolYear + "-" + (schoolYear + 1);
      this.schoolYearList.push(formattedSchoolYear);

      schoolYear++;
    }
  }
}
