<img class="side-image" src="/assets/images/Connexion_Background.png" />
<div class="side-register">
    <div fxLayout fxFill fxLayoutAlign="space-around center">
        <div class="vertical">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>
                        <mat-icon class="material-icons-outlined">account_circle</mat-icon>
                        <div>Inscription</div>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <form class="vertical" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                        <mat-form-field appearance="fill" style="width: 60%">
                            <mat-label>Nom d'utilisateur</mat-label>
                            <input matInput formControlName="username" required>
                            <mat-error *ngIf="registerForm.controls['username'].invalid">{{getUsernameErrorMessage()}}</mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="fill" style="width: 60%">
                            <mat-label>Adresse email</mat-label>
                            <input matInput formControlName="email" required>
                            <mat-error *ngIf="registerForm.controls['email'].invalid">{{getEmailErrorMessage()}}</mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="fill" style="width: 60%">
                            <mat-label>Mot de passe</mat-label>
                            <div class="horizontal">
                                <input matInput [type]="showPassword ? 'text' : 'password'" formControlName="password" required>
                                <mat-icon (click)="togglePasswordVisibility()">{{showPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
                            </div>
                            <mat-error *ngIf="registerForm.controls['password'].invalid">{{getPasswordErrorMessage()}}</mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="fill" style="width: 60%">
                            <mat-label>Confirmer mot de passe</mat-label>
                            <div class="horizontal">
                                <input matInput [type]="showConfirmPassword ? 'text' : 'password'" formControlName="confirmPassword" required>
                                <mat-icon (click)="toggleConfirmPasswordVisibility()">{{showConfirmPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
                            </div>
                            <mat-error *ngIf="registerForm.controls['confirmPassword'].invalid">{{getConfirmPasswordErrorMessage()}}</mat-error>
                        </mat-form-field>
                        <mat-checkbox formControlName="requestTrainerRole">Demander le rôle enseignant</mat-checkbox>
                        <mat-checkbox formControlName="requestManagerRole">Demander le rôle organisation tournoi</mat-checkbox>
                        <p style="color: red;" *ngIf="registerForm.errors?.noMatch">Les mots de passe ne correspondent pas</p>
                        <div class="horizontal">
                            <button mat-raised-button color="accent" type="submit" [disabled]="!registerForm.valid">S'inscrire</button>
                        </div>
                        <!--<re-captcha (resolved)="resolved($event)" siteKey="YOUR_SITE_KEY"></re-captcha>-->
                        <p style="color: red;" *ngIf="connectionError !== ''">{{connectionError}}</p>
                    </form>
                </mat-card-content>
            </mat-card>
            <a routerLink="/login" [queryParams]="{role: loginType}">Vous avez déjà un compte ?</a>
        </div>
    </div>
</div>
