import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { TokenService } from './token.service';

export interface License {
  _id: string,
  startDate: Date,
  endDate: Date,
  group: string,
  key: string,
  organization: string,
  seats: number,
}

export interface Organization {
  _id: string,
  name: string,
  logo: string,
  owners: string[],
}

export interface Role {
  _id: string,
  name: string,
}

export interface User {
  _id: string,
  emails: string[],
  licenseList: License[],
  role: Role | Role[],
  username: string,
  organization: Organization,
}

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  public currentUser: User | null = null;

  public userRoles = ["NOT_CONNECTED"];
  private isFetchingUserInfos = false;

  constructor(
    private tokenService: TokenService,
    private apiService: ApiService,
  ) { }

  public isRoleAllowed(allowedRoles: string[]): boolean {
    if (this.tokenService.getToken() && !this.isFetchingUserInfos && this.userRoles[0] === "NOT_CONNECTED") {
      this.fetchUserInfos((user) => {});
      return false;
    } else {
      for (let i = 0; i < allowedRoles.length; i++) {
        if (this.userRoles.includes(allowedRoles[i]) && allowedRoles[i] === localStorage.getItem('loginType')) {
          return true;
        }
      }

      return false;
    }
  }

  public getUsername(): string {
    return this.currentUser ? this.currentUser.username : "NO_USER";
  }

  public fetchUserInfos(callback: ((user: User) => void)|null = null, forceUpdateUser : boolean = false): void {
    this.isFetchingUserInfos = true;

    if (this.currentUser && !forceUpdateUser) {
      if (callback) {
        callback(this.currentUser);
      }
    } else {
      this.apiService.getCurrentUserDetails().subscribe((user: User) => {
        this.currentUser = user;
        this.userRoles = [];
        console.log("User : " + user.licenseList.length);

        const roleList: Role[] = [];

        console.log(user.role.constructor);
        console.log(roleList.constructor);

        if (user.role.constructor == Array) {
          user.role.forEach(roleItem => {
            this.userRoles.push(roleItem.name);
          });
        } else {
          this.userRoles.push((<Role>user.role).name);
        }
        this.isFetchingUserInfos = false;

        if (callback) {
          callback(user);
        }
      });
    }
  }
}
