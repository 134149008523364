import { HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { Subscription } from 'rxjs';
import { ApiService } from '../services/api.service';

export enum Progress {
  buffering,
  inProgress,
  error,
  done
}

@Component({
  selector: 'app-download-game',
  templateUrl: './download-game.component.html',
  styleUrls: ['./download-game.component.scss']
})
export class DownloadGameComponent implements OnInit {
  public progress = Progress.done;
  public progressValue = 0;

  private downloadSubscription: Subscription | null = null;

  constructor(
    public apiService: ApiService
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (this.downloadSubscription !== null) {
      this.downloadSubscription.unsubscribe();
    }
  }

  public downloadGame(platform: string): void {
    if (!this.canDownload) {
      return;
    }

    this.downloadSubscription = this.apiService.downloadGame(platform).subscribe((data) => {
      if (data.type === HttpEventType.DownloadProgress) {
        const percentDone = Math.round(100 * data.loaded / data.total);
        this.updateDownloadProgressState(platform, Progress.inProgress);
        this.updateDownloadProgressValue(platform, percentDone);
      } else if (data.type === HttpEventType.Response) {
        const blob = new Blob([data.body], {type: "application/zip"});

        const downloadUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = "Roots of Tomorrow_" + platform + ".zip";
        link.click();
        URL.revokeObjectURL(downloadUrl);
        this.downloadSubscription = null;
        this.updateDownloadProgressState(platform, Progress.done);
      } else {
        this.updateDownloadProgressState(platform, Progress.buffering);
      }
    }, (error) => {
      console.log("Download failed", error);
      this.updateDownloadProgressState(platform, Progress.error);
    });
  }

  public get canDownload(): boolean {
    return this.progress === Progress.error || this.progress === Progress.done;
  }

  public get progressMode(): ProgressBarMode {
    return this.progress === Progress.inProgress ? 'determinate' : 'buffer';
  }

  private updateDownloadProgressState(platform: string, newProgress: Progress): void {
    this.progress = newProgress;
  }

  private updateDownloadProgressValue(platform: string, value: number): void {
    this.progressValue = value;
  }

}
