import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { RoleService } from './services/role.service';
import { AuthService } from './services/auth.service';
import { TokenService } from './services/token.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  previousUrl = '';

  constructor(private authService: AuthService, private tokenService: TokenService, private roleService: RoleService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const url: string = state.url;

      const result = this.checkLogin(url) && this.checkRole(route);

      if (result) {
        this.previousUrl = url;
        console.log(this.previousUrl);
      }

      return result;
  }

  checkLogin(url: string): boolean {
    if (this.tokenService.getRefreshToken()) {
      return true;
    }

    this.authService.redirectUrl = url;
    this.router.navigate(['/home']);

    return false;
  }

  checkRole(route: ActivatedRouteSnapshot): boolean {
    if (route.data.allowedRoles === undefined) {
      return true;
    }

    const allowedRoles: string[] = route.data.allowedRoles;

    if (this.roleService.isRoleAllowed(allowedRoles)) {
      return true;
    }

    this.router.navigate([this.previousUrl]);
    return false;
  }
}
