import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl, AbstractControlOptions, ValidationErrors, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '../services/auth.service';
import { DialogRoleRequestComponent } from './dialog-role-request/dialog-role-request.component';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  public registerForm: FormGroup;
  public connectionError = '';

  public showPassword = false;
  public showConfirmPassword = false;

  public loginType = 'learner';

  private emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  constructor(private authService: AuthService, private formBuilder: FormBuilder, private router: Router, private dialog: MatDialog, private route: ActivatedRoute) {
    this.registerForm = formBuilder.group({
      username: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      password: ['', [Validators.required, Validators.minLength(6)]], // TODO ajouter des contraintes plus fortes sur le mot de passe
      confirmPassword: ['', Validators.required],
      requestTrainerRole: false,
      requestManagerRole: false,
    }, {validators: this.matchPassword});
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params) => {
      if (params.keys.includes('role')) {
        if (params.get('role')) {
          this.loginType = params.get('role')!;
        } else {
          this.loginType = 'learner';
        }
      } else {
        this.loginType = 'learner';
      }
    });
  }

  public togglePasswordVisibility() {
    this.showPassword = !this.showPassword
  }

  public toggleConfirmPasswordVisibility() {
    this.showConfirmPassword = !this.showConfirmPassword
  }

  public getUsernameErrorMessage() {
    if (this.registerForm.controls['username'].hasError('required')) {
      return 'Vous devez entrer un nom d\'utilisateur';
    }

    return '';
  }

  public getEmailErrorMessage() {
    if (this.registerForm.controls['email'].hasError('required')) {
      return 'Vous devez entrer un email';
    }

    return this.registerForm.controls['email'].hasError('email') ? 'L\'email doit être valide' : '';
  }

  public getPasswordErrorMessage() {
    if (this.registerForm.controls['password'].hasError('required')) {
      return 'Vous devez entrer un mot de passe';
    }

    return this.registerForm.controls['password'].hasError('minlength') ? 'Le mot de passe doit contenir au moins 6 caractères' : '';
  }

  public getConfirmPasswordErrorMessage() {
    if (this.registerForm.controls['confirmPassword'].hasError('required')) {
      return 'Vous devez entrer un mot de passe';
    }

    return '';
  }

  public onSubmit() {
    this.authService.baseRegister(this.registerForm.value).subscribe(() => {
      this.authService.login(this.registerForm.value).subscribe(() => {
        this.connectionError = '';
        localStorage.setItem('remember_me', 'false');
        localStorage.setItem('loginType', 'learner');
        if (this.registerForm.controls['requestTrainerRole'].value || this.registerForm.controls['requestManagerRole'].value) {
          this.dialog.open(DialogRoleRequestComponent);
        } else {
          this.router.navigate([this.authService.redirectUrl]);
        }
      });
    }, (err: any) => {
      this.connectionError = 'Erreur lors de l\'inscription';

      console.log(this.connectionError);
    });
  }

  public resolved(captchaResponse: any): void {
    console.log("Resolved captcha with response: " + captchaResponse);
  }

  private matchPassword(control: AbstractControl): ValidationErrors | null {
    const password = control.get("password")?.value;
    const confirm = control.get("confirmPassword")?.value;
 
    if (password != confirm) {
      return { 'noMatch': true };
    }
 
    return null;
  }
}