import { NavigationService } from './../navigation/navigation.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(
    private navigationService: NavigationService,
    public roleService: RoleService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  public isUrlPublic(): boolean {
    const publicUrlList = ['/login', '/register', '/reset-password', '/home'];

    for (let i = 0; i < publicUrlList.length; i++) {
      if (this.router.url.indexOf(publicUrlList[i]) !== -1) {
        return true;
      }
    }

    return false;
  }

  public returnToMainPage(): void {
    this.router.navigate(['/home']);
  }

  public get title(): string {
    return this.navigationService.getTitle();
  }
}
