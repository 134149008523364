import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private sidenavOpened = false;
  private defaultTitle = "";
  private title = "";

  constructor() {
    this.clearTitle();
  }

  public toggleSidenav(): void {
    this.sidenavOpened = !this.sidenavOpened;
  }

  public closeSidenav(): void {
    this.sidenavOpened = false;
  }

  public get SidenavOpened() {
    return this.sidenavOpened;
  }

  public getTitle(): string {
    return this.title;
  }

  public setTitle(title: string): void {
    this.title = title;
  }

  public clearTitle(): void {
    this.title = this.defaultTitle;
  }
}
