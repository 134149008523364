<form [formGroup]="classForm" (ngSubmit)="saveClass()">
    <mat-card>
        <h2>Gérer votre classe</h2>

        <span class="horizontal-align spaced">
            <span>
                <p>Fichier schéma pour l'ajout d'élèves (Ne pas supprimer la première ligne SVP)</p>
                <a mat-raised-button color="accent" href="assets/classes/Liste élève - Schéma.csv">Télécharger</a>
                <span class="horizontal-align">
                    <mat-form-field>
                        <mat-label>Nom de la classe</mat-label>
                        <input matInput type="text" formControlName="className">
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Année scolaire</mat-label>
                        <mat-select formControlName="schoolYear">
                            <mat-option *ngFor="let schoolYear of schoolYearList" [value]="schoolYear">
                                {{ schoolYear }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </span>

                <span class="horizontal-align">
                    <input type="file" accept=".csv" id="members-input" style="display: none;" (change)="getStudentFile($event)" onclick="this.value=null;">
                    <button mat-raised-button color="accent" type="button" onclick="document.querySelector('#members-input').click()">
                        Importer des élèves
                    </button>
                    <p>Maximum {{ this.maxStudents }} élèves</p>
                </span>

                <p *ngIf="selectedStudentError" class="error">{{ selectedStudentError }}</p>
                <p *ngIf="csvIntegrityError" class="error">Erreur dans votre fichier CSV : {{ csvIntegrityError }}</p>
                <p *ngIf="repeatedNameError" class="error">{{ repeatedNameError }}</p>
            </span>

            <span>
                <mat-card class="inner-card">
                    <h2>Groupes</h2>
                    <span *ngFor="let group of groupList">
                        <span class="horizontal-align">
                            <mat-card [ngClass]="{'deleted': group.deleted}" (click)="showSubgroupSelect(group)">{{group.name}}</mat-card>
                            <button *ngIf="!group.deleted" mat-raised-button color="warn" type="button" (click)="removeGroup(group)">
                                <mat-icon>delete</mat-icon>
                            </button>
                            <button *ngIf="group.deleted" mat-raised-button color="accent" type="button" (click)="restoreGroup(group)">
                                <mat-icon>replay</mat-icon>
                            </button>
                        </span>
                    </span>

                    <button mat-stroked-button type="button" (click)="createGroup()">
                        <mat-icon>add</mat-icon>
                        <span>Ajouter groupe</span>
                    </button>
                </mat-card>
            </span>
        </span>

        <div class="horizontal-align centered">
            <button mat-raised-button color="accent" type="submit" [disabled]="!classForm.valid || !changed">
                Enregistrer
            </button>
            <button mat-raised-button [color]="changed?'warn':'accent'" type="button" (click)="goBack()">
                Retour
            </button>
        </div>
    </mat-card>

    <table mat-table [dataSource]="userList" matSort *ngIf="userList.length > 0">
        <ng-container matColumnDef="username">
            <th mat-header-cell *matHeaderCellDef>
                Nom d'utilisateur
            </th>
            <td mat-cell *matCellDef="let student">{{ student.username }}</td>
        </ng-container>

        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>
                Email
            </th>
            <td mat-cell *matCellDef="let student">{{ student.emails[0] }}</td>
        </ng-container>

        <ng-container matColumnDef="groups">
            <th mat-header-cell *matHeaderCellDef>
                Groupes
            </th>
            <td mat-cell *matCellDef="let student">{{ student.groups.join(', ') }}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let student">
                <button *ngIf="!student.deleted" mat-raised-button type="button" color="warn" (click)="removeStudent(student)">
                    <mat-icon>delete</mat-icon>
                </button>
                <button *ngIf="student.deleted" mat-raised-button type="button" color="accent" (click)="restoreStudent(student)">
                    <mat-icon>replay</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'deleted': row.deleted}"></tr>
    </table>
</form>
