<img class="side-image" src="/assets/images/Connexion_Background.png" />
<div class="side-login">
    <div fxLayout fxFill fxLayoutAlign="space-around center">
        <mat-card>
            <mat-card-header>
                <mat-card-title>
                    <mat-icon class="material-icons-outlined">account_circle</mat-icon>
                    <div>Récupération de mot de passe</div>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <ng-container *ngIf="!isResetting(); else resetPassword">
                    <ng-container *ngIf="!hasSentRecover(); else recoverSent">
                        <form class="vertical" [formGroup]="usernameForm" (ngSubmit)="submitRecover()">
                            <mat-form-field appearance="fill">
                                <mat-label>Nom d'utilisateur</mat-label>
                                <input matInput formControlName="username" required>
                                <mat-error *ngIf="usernameForm.controls['username'].invalid">{{getUsernameErrorMessage()}}</mat-error>
                            </mat-form-field>
                            <div class="horizontal">
                                <button mat-raised-button color="accent" type="submit" [disabled]="!usernameForm.valid">Récupérer</button>
                                <button mat-raised-button color="warn" routerLink="/login">Retour</button>
                            </div>
                            <p style="color: red;" *ngIf="recoverError !== ''">{{recoverError}}</p>
                        </form>
                    </ng-container>
                    <ng-template #recoverSent>
                        <div class="vertical">
                            <p>{{recoverMessage}}</p>
                            <button mat-raised-button color="accent" routerLink="/login">Retour</button>
                        </div>
                    </ng-template>
                </ng-container>
                <ng-template #resetPassword>
                    <form class="vertical" [formGroup]="resetForm" (ngSubmit)="submitReset()">
                        <mat-form-field appearance="fill">
                            <mat-label>Mot de passe</mat-label>
                            <input matInput type="password" formControlName="password" required>
                            <mat-error *ngIf="resetForm.controls['password'].invalid">{{getPasswordErrorMessage()}}</mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>Confirmer mot de passe</mat-label>
                            <input matInput type="password" formControlName="confirmPassword" required>
                            <mat-error *ngIf="resetForm.controls['confirmPassword'].invalid">{{getConfirmPasswordErrorMessage()}}</mat-error>
                        </mat-form-field>
                        <div class="horizontal">
                            <button mat-raised-button color="accent" type="submit" [disabled]="!resetForm.valid">Réinitialiser</button>
                            <button mat-raised-button color="warn" routerLink="/login">Annuler</button>
                        </div>
                        <p style="color: red;" *ngIf="recoverError !== ''">{{recoverError}}</p>
                    </form>
                </ng-template>
            </mat-card-content>
        </mat-card>
    </div>
</div>
