<!-- Game Rules -->
<mat-card class="rules-window" style="background-color: darkgrey;">
    <mat-card-header style="justify-content: center;">
        <mat-card-title>Règles</mat-card-title>
    </mat-card-header>
    <mat-card-content [style.overflow]="'auto'" [style.height.px]="'200'">
        <mat-card class="rule-card">
            <p><b>Scénario : </b>{{gameScenarioName}}</p>
        </mat-card>
        <mat-card class="rule-card">
            <p><b>Durée tours : </b>{{game.properties.turnLength}} semaines</p>
        </mat-card>
        <mat-card class="rule-card">
            <p><b>Recherche à obtenir : </b>{{gameResearchName}}</p>
        </mat-card>
        <mat-card class="rule-card">
            <p><b>Choix à obtenir : </b>{{gameObjectiveName}}</p>
        </mat-card>
        <mat-card class="rule-card">
            <p><b>Score économique à atteindre : </b>{{game.properties.ratings.economical}} / 5</p>
        </mat-card>
        <mat-card class="rule-card">
            <p><b>Score environnemental à atteindre : </b>{{game.properties.ratings.environmental}} / 5</p>
        </mat-card>
        <mat-card class="rule-card">
            <p><b>Score social à atteindre : </b>{{game.properties.ratings.social}} / 5</p>
        </mat-card>
        <mat-card class="rule-card">
            <p><b>Score global à atteindre : </b>{{game.properties.ratings.global}} / 5</p>
        </mat-card>
    </mat-card-content>
</mat-card>

<table mat-table *ngIf="playedGamesList !== undefined" [dataSource]="playedGamesList" matSort>
    <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Username </th>
        <td mat-cell *matCellDef="let playedGame"> {{ playedGame.user.username }} </td>
    </ng-container>

    <ng-container matColumnDef="currentTurn">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Tour </th>
        <td mat-cell *matCellDef="let playedGame"> {{ playedGame.currentTurn }}/{{ playedGame.maxTurns }} </td>
    </ng-container>

    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Statut </th>
        <td mat-cell *matCellDef="let playedGame"> {{ playedGame.status }} </td>
    </ng-container>

    <ng-container matColumnDef="timePlayed">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Temps de jeu </th>
        <td mat-cell *matCellDef="let playedGame"> {{ numberToTime(playedGame.timePlayed) }} </td>
    </ng-container>

    <ng-container matColumnDef="score">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Score </th>
        <td mat-cell *matCellDef="let playedGame"> {{ playedGame.score }} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: tableColumns"></tr>
</table>