import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { RoleService, License } from '../services/role.service';

export interface User {
  _id: string,
  emails: string[],
  licenseList: License[],
  username: string,
  organization: string,
}

export interface Group {
  _id: string,
  name: string,
  members: string[]
}

export interface Game {
  _id: string,
  name: string,
  owners: User[],
  visibility: string,
  code: string,
  group: Group,
  startDate: Date,
  endDate: Date,
  orgLogo: string,
}

@Component({
  selector: 'app-games-list',
  templateUrl: './games-list.component.html',
  styleUrls: ['./games-list.component.scss']
})
export class GamesListComponent implements OnInit {
  public currentGames: Game[] = [];

  public finishedGames: Game[] = [];

  constructor(
    private apiService: ApiService,
    public roleService: RoleService,
    private snackBar: MatSnackBar,
    private router: Router,
  ) { }

  ngOnInit(): void {
    if (!this.roleService.currentUser) {
      this.roleService.fetchUserInfos((user) => {
        this.getGames();
      });
    } else {
      this.getGames();
    }
  }

  private getGames(): void {
    this.finishedGames = [];
    this.currentGames = [];


    this.apiService.getGameFromUser().subscribe((gameList: Game[]) => {
      gameList.forEach((game: Game) => {
        if (new Date(game.endDate) < new Date(Date.now())) {
          this.finishedGames.push(game);
        } else {
          this.currentGames.push(game);
        }
      })
    }, (err: any) => {
      console.log('Error : ' + err);
    });
  }

  public goToGameDetails(gameId: string) {
    this.router.navigate(['/game-details/' + gameId]);
  }

  public deleteGame(gameId: string) {
    this.apiService.deleteGame(gameId).subscribe((result) => {
      this.getGames();
    }, (error) => {
      console.log(error);
    });
  }

  public goToGameCreation() {
    this.router.navigate(['/game-create']);
  }

  public gameVisibility(game: Game): string {
    switch (game.visibility) {
      case 'public':
        return 'publique';
      case 'private':
        return 'privée';
      case 'group':
        return 'privée';
      default:
        return '';
    }
  }

  public isGameOwnedByUser(game: Game): boolean {
    for (let i = 0; i < game.owners.length; i++) {
      if (game.owners[i]._id === this.roleService.currentUser!._id) {
        return true;
      }
    }

    return false;
  }

  public isMyGamePrivate(game: Game): boolean {
    return game.visibility === 'private' && this.isGameOwnedByUser(game);
  }

  public isGameChallenge(game: Game): boolean {
    return true;
  }

  public notifyCopy(): void {
    this.snackBar.open('Code copié', 'OK');
  }
}
