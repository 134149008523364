import { ApiService } from './../../services/api.service';
import { Router } from '@angular/router';
import { ClassService } from './../class.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { License } from 'src/app/services/role.service';

export interface ClassGroup {
  _id: string,
  name: string,
  owners: string[],
  maxMembers: number,
  attributes: Record<string, string>,
  license: string,
}

@Component({
  selector: 'app-class-create',
  templateUrl: './class-create.component.html',
  styleUrls: ['./class-create.component.scss']
})
export class ClassCreateComponent implements OnInit {
  public classForm = new FormGroup({
    className: new FormControl('', Validators.required),
    schoolYear: new FormControl(null, Validators.required)
  });

  public schoolYearList: string[] = [];

  constructor(
    private dialogRef: MatDialogRef<ClassCreateComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {license: License},
    private apiService: ApiService,
    private classService: ClassService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.schoolYearList = this.classService.schoolYears;
  }

  public createClass(): void {
    this.apiService.getCurrentUserDetails().subscribe((userId) => {
      const groupObject: ClassGroup = {
        _id: '',
        name: this.classForm.controls['className'].value,
        owners: [userId],
        maxMembers: 30,
        attributes: {
          schoolYear: this.classForm.controls['schoolYear'].value
        },
        license: this.data.license._id
      }

      this.apiService.postGroup(groupObject).subscribe((group) => {
        this.router.navigate(['/class', group._id]);
        this.dialogRef.close();
      });
    });
  }
}
