import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { TournamentsListComponent } from './tournaments-list/tournaments-list.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GamesListComponent } from './games-list/games-list.component';
import { AuthGuard } from './auth.guard';
import { GameCreateComponent } from './game-create/game-create.component';
import { GameDetailsComponent } from './game-details/game-details.component';
import { ClassesListComponent } from './classes-list/classes-list.component';
import { LoginComponent } from './login/login.component';
import {HomeComponent, HomeDialogComponent, HomeNewsDialogComponent} from './home/home.component';
import { LogoutComponent } from './logout/logout.component';
import { ProfileManagementComponent } from './profile-management/profile-management.component';
import { RegisterComponent } from './register/register.component';
import { DownloadGameComponent } from './download-game/download-game.component';
import { HelpComponent } from './help/help.component';
import { ClassComponent } from './class/class.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/games-list',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'reset-password',
    children: [{
      path: '',
      component: ResetPasswordComponent,
    }, {
      path: ':resetId',
      component: ResetPasswordComponent,
    }]
  },
  {
    path: 'games-list',
    canActivate: [AuthGuard],
    component: GamesListComponent
  },
  {
    path: 'classes-list',
    canActivate: [AuthGuard],
    component: ClassesListComponent
  },
  {
    path: 'tournaments-list',
    canActivate: [AuthGuard],
    component: TournamentsListComponent
  },
  {
    path: 'game-details/:gameId',
    canActivate: [AuthGuard],
    component: GameDetailsComponent
  },
  {
    path: 'game-create',
    canActivate: [AuthGuard],
    data: {allowedRoles: ["trainer", "manager"]},
    component: GameCreateComponent
  },
  {
    path: 'class/:classId',
    canActivate: [AuthGuard],
    data: {allowedRoles: ["trainer"]},
    component: ClassComponent
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    component: ProfileManagementComponent
  },
  {
    path: 'download',
    canActivate: [AuthGuard],
    data: {allowedRoles: ["trainer", "manager", "learner"]},
    component: DownloadGameComponent
  },
  {
    path: 'help',
    canActivate: [AuthGuard],
    component: HelpComponent
  },
  {
    path: '**',
    redirectTo: '/games-list'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
