import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private static handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError({status: error.status, message: error.error.error});
  }

  constructor(private http:HttpClient, private environmentService: EnvironmentService) { }

  public createUsers(users: any): Observable<any> {
    return this.http.post<any>(this.environmentService.apiUrl + '/new/users', users)
      .pipe(catchError(ApiService.handleError));
  }

  public getUniqueUsername(username: string): Observable<any> {
    return this.http.post<any>(this.environmentService.apiUrl + '/user/name', {username: username})
      .pipe(catchError(ApiService.handleError));
  }

  public getOrganizationFromId(organizationId: string): Observable<any> {
    return this.http.get<any>(this.environmentService.apiUrl + '/organization/' + organizationId)
      .pipe(catchError(ApiService.handleError));
  }

  public getGameFromUser(): Observable<any> {
    return this.http.get<any>(this.environmentService.apiUrl + '/game')
      .pipe(catchError(ApiService.handleError));
  }

  public getGroup(groupId: string): Observable<any> {
    return this.http.get<any>(this.environmentService.apiUrl + '/group/' + groupId)
      .pipe(catchError(ApiService.handleError));
  }

  public getUsersIdFromGroupId(groupId: string): Observable<any> {
    return this.http.get<any>(this.environmentService.apiUrl + '/group/' + groupId)
      .pipe(catchError(ApiService.handleError));
  }

  public postHTMLFromURL(url : string): Observable<any> {
    return this.http.post<any>(this.environmentService.apiUrl + '/htmlUrl', {url : url})
      .pipe(catchError(ApiService.handleError));
  }

  public postGroup(group: any): Observable<any> {
    return this.http.post<any>(this.environmentService.apiUrl + '/group', group)
      .pipe(catchError(ApiService.handleError));
  }

  public updateGroup(group: any): Observable<any> {
    return this.http.patch<any>(this.environmentService.apiUrl + '/group/' + group._id, group)
      .pipe(catchError(ApiService.handleError));
  }

  public deleteGroup(groupId: string): Observable<any> {
    return this.http.delete<any>(this.environmentService.apiUrl + '/group/' + groupId)
      .pipe(catchError(ApiService.handleError));
  }

  public deleteMemberFromGroup(groupId: string, userId: string): Observable<any> {
    return this.http.delete<any>(this.environmentService.apiUrl + '/group/' + groupId + '/member/' + userId)
      .pipe(catchError(ApiService.handleError));
  }

  public getTournamentGamesFromUser(): Observable<any> {
    return this.http.get<any>(this.environmentService.apiUrl + '/tournament')
      .pipe(catchError(ApiService.handleError));
  }

  public getGroupsFromUser(): Observable<any> {
    return this.http.get<any>(this.environmentService.apiUrl + '/group')
      .pipe(catchError(ApiService.handleError));
  }

  public getGroupsFromUserOwner(): Observable<any> {
    return this.http.get<any>(this.environmentService.apiUrl + '/group?ownerOnly=1')
      .pipe(catchError(ApiService.handleError));
  }

  public getPlayedGamesFromGame(gameId: string): Observable<any> {
    return this.http.get<any>(this.environmentService.apiUrl + '/played_game/game/' + gameId)
      .pipe(catchError(ApiService.handleError));
  }

  public getGameFromId(gameId: string): Observable<any> {
    return this.http.get<any>(this.environmentService.apiUrl + '/game/' + gameId)
      .pipe(catchError(ApiService.handleError));
  }

  public getNewsGame(count : number) : Observable<any> {
    return this.http.get<any>(this.environmentService.apiUrl + '/news/roots/' + count)
      .pipe(catchError(ApiService.handleError));
  }

  public postGame(gameObject: any): Observable<any> {
    return this.http.post<any>(this.environmentService.apiUrl + '/game', gameObject)
      .pipe(catchError(ApiService.handleError));
  }

  public updateGame(gameId: string, gameObject: any): Observable<any> {
    return this.http.patch<any>(this.environmentService.apiUrl + '/game/' + gameId, gameObject)
      .pipe(catchError(ApiService.handleError));
  }

  public deleteGame(gameId: string): Observable<any> {
    return this.http.delete<any>(this.environmentService.apiUrl + '/game/' + gameId)
      .pipe(catchError(ApiService.handleError));
  }

  public deleteUser(userId : string): Observable<any> {
    return this.http.delete<any>(this.environmentService.apiUrl + '/user/' + userId)
      .pipe(catchError(ApiService.handleError));
  }

  public getCurrentUserDetails(): Observable<any> {
    return this.http.post<any>(this.environmentService.apiUrl + '/me', {})
      .pipe(catchError(ApiService.handleError));
  }

  public updateUserInfos(userObject: any): Observable<any> {
    return this.http.patch<any>(this.environmentService.apiUrl + '/profile', userObject)
      .pipe(catchError(ApiService.handleError));
  }

  public downloadGame(platform: string): Observable<any> {
    return this.http.get<any>(this.environmentService.apiUrl + '/download/' + platform, { responseType: 'blob' as 'json', reportProgress: true, observe: 'events' })
      .pipe(catchError(ApiService.handleError));
  }

  public sendMail(mailObject: any): Observable<any> {
    return this.http.post<any>(this.environmentService.apiUrl + '/email', mailObject)
      .pipe(catchError(ApiService.handleError));
  }

  public sendSpecificMailToUser(userId : string, mailObject: any): Observable<any> {
    return this.http.post<any>(this.environmentService.apiUrl + '/user/email/' + userId, mailObject)
      .pipe(catchError(ApiService.handleError));
  }

  public fetchSteamNews(): Observable<any> {
    return this.http.get<any>(this.environmentService.apiUrl + '/news/roots/2')
      .pipe(catchError(ApiService.handleError));
  }
}
