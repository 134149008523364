<div fxLayout fxFill fxLayoutAlign="space-around center">
    <form [formGroup]="profileForm" (ngSubmit)="patchProfile()">
        <mat-card>
            <div class="title">
                <mat-icon class="material-icons-outlined">account_circle</mat-icon>
                <h2>
                    Modifier votre profil
                </h2>
            </div>
    
            <span class="horizontal-align">
                <mat-form-field>
                    <input matInput type="text" placeholder="Nom d'utilisateur" formControlName="username">
                </mat-form-field>
            </span>
    
            <span class="horizontal-align">
                <mat-form-field>
                    <input matInput type="text" placeholder="Email" formControlName="email">
                </mat-form-field>
            </span>
    
            <span class="horizontal-align">
                <mat-form-field>
                    <input matInput type="password" placeholder="Ancien mot de passe" formControlName="oldPassword">
                </mat-form-field>
            </span>
    
            <span class="horizontal-align">
                <mat-form-field>
                    <input matInput type="password" placeholder="Nouveau mot de passe" formControlName="newPassword">
                </mat-form-field>
            </span>
            
            <button
                mat-raised-button
                color="primary"
                id="submit-form"
                type="submit"
                [disabled]="!profileForm.valid">
                Valider
            </button>
    
            <p style="text-align: center; color: blue;" *ngIf="successMessage !== ''">{{successMessage}}</p>
            <p style="text-align: center; color: red;" *ngIf="errorMessage !== ''">{{errorMessage}}</p>
        </mat-card>
    </form>
</div>
