import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-navigation-item',
  templateUrl: './navigation-item.component.html',
  styleUrls: ['./navigation-item.component.scss']
})
export class NavigationItemComponent implements OnInit {
  @Input() icon = "";
  @Input() link = "";
  @Input() text = "";

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  public get isActive(): boolean {
    return this.router.url === this.link;
  }

}
