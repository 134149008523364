import { PopupComponent } from './../popup/popup.component';
import { ApiService } from './../services/api.service';
import { ClassService } from './class.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { WorkgroupCreateComponent } from './workgroup-create/workgroup-create.component';
import * as _ from 'lodash';

export interface UserCreateBody {
  mailSender: string,
  mailSubject: string,
  mailBody: string,
  mailSignature: string,
  users: User[]
}

export interface User {
  _id: string,
  username: string,
  emails: string[],
  password: string,
  role: string[],
  groups?: string[],
  created?: boolean,
  deleted: boolean
}

export interface Group {
  _id: string,
  name: string,
  owners?: string[],
  maxMembers?: number,
  members: User[] | string[],
  groups?: Group[] | string[],
  attributes?: Record<string, string>,
  updated?: boolean,
  deleted: boolean
}

export class Student {
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  group: string = ''
  password: string = '';

  constructor(firstName: string, lastName: string, email: string) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
  }

  setPropertyFromHeader(headerName: string, value: string) {
    switch (headerName.toLowerCase().trim()) {
      case 'prénom':
        this.firstName = value;
        break;

      case 'nom':
        this.lastName = value;
        break;

      case 'nom complet':
        let name = value.split(' ');

        if (name.length === 2) {
          this.firstName = name[0];
          this.lastName = name[1];
        }

        break;

      case 'email':
        this.email = value;
        break;

      case 'groupe':
        this.group = value;
        break;

      case 'password':
        this.password = value;
        break;

      default:
        break;
    }
  }
}

@Component({
  selector: 'app-class',
  templateUrl: './class.component.html',
  styleUrls: ['./class.component.scss']
})
export class ClassComponent implements OnInit {
  public classForm = new FormGroup({
    className: new FormControl('', Validators.required),
    schoolYear: new FormControl({value: null, disabled: true}, Validators.required)
  });
  public displayedColumns: string[] = ['username', 'email', 'groups', 'actions'];

  public schoolYearList: string[] = [];

  private groupId: string | null = null;
  private group: Group | null = null;
  private changedForm: boolean = false;
  private changedStudents: boolean = false;
  private changedGroups: boolean = false;
  private userTempIds: string[] = [];

  public selectedStudentFile: string | undefined = undefined;
  public selectedStudentError: string = '';
  public selectedStudents: Student[] = [];

  public userList: User[] = [];
  public groupList: Group[] = [];
  public csvIntegrityError: string = '';
  public repeatedNameError: string;
  public csvIntegrity: boolean | undefined = undefined;

  constructor(
    public dialog: MatDialog,
    private apiService: ApiService,
    private classService: ClassService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.classForm.valueChanges.subscribe((data) => {
      this.changedForm = data.className !== this.group?.name;
    });

    this.schoolYearList = this.classService.schoolYears;
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.groupId = params.get('classId');

      if (this.groupId !== null) {
        this.apiService.getGroup(this.groupId).subscribe((group) => {
          this.loadGroup(group);
        });
      }
    });
  }

  public getStudentFile(event: Event) {
    const fileList: FileList | null = (event.target as HTMLInputElement).files;

    if (!fileList) {
      console.log("No file!");
    } else {
      const file: File | null = fileList.item(0);
      if (file) {
        this.selectedStudentFile = file.name;
        this.checkCsvIntegrity(file).then(() => {

          if (this.csvIntegrity) {
            this.parseCsvFile(file).then(() => {
              this.convertStudentsToUser(this.selectedStudents).then((newUsers) => {
                if (newUsers.length > 0) {
                  this.userList = this.userList.concat(newUsers);
                  this.changedStudents = true;

                  this.createGroupsFromUsers(newUsers);
                }
              });
            });
          }
        });
      }
    }
  }

  public removeStudent(student: User) {
    student.deleted = true;

    this.changedStudents = this.userList.some((user) => (user.deleted && user._id !== '') || (!user.deleted && user.created));
  }

  public restoreStudent(student: User) {
    student.deleted = false;

    this.changedStudents = this.userList.some((user) => (user.deleted && user._id !== '') || (!user.deleted && user.created));
  }

  public goBack() {
    if (this.changed) {
      this.dialog.open(PopupComponent, {
        data: {
          text: 'Vous avez des données non enregistrées. Voulez-vous vraiment quitter ?',
          confirm: 'Oui',
          confirmColor: 'accent',
          cancel: 'Non',
          cancelColor: 'warn'
        }
      }).afterClosed().subscribe((value) => {
        if (value) {
          this.router.navigate(['/classes-list']);
        }
      });
    } else {
      this.router.navigate(['/classes-list']);
    }
  }

  private checkCsvIntegrity(file: File): Promise<void> {
    return new Promise((resolve) => {
      // Check if the file is a CSV
      if (!file.name.endsWith('.csv')){
        this.csvIntegrityError = `Le fichier soumis n'est pas au format CSV.`;
        this.csvIntegrity = false;
        resolve();
        return;
      }

      const reader = new FileReader();

      reader.onload = () => {
        const data = reader.result as string;
        // Split the CSV data into rows
        const rows = data.split(/\r\n|\n/);

        // Check that the file is not empty
        if (rows.length === 0) {

          this.csvIntegrityError = `Le fichier CSV est vide.`;
          this.csvIntegrity = false;
          resolve();
          return;
        }

        // Loop through each row
        for (let i = 0; i < rows.length - 1; i++) {
          const row = rows[i];

          // Split the row into columns
          const columns = row.split(",");

          // Check that the header is in the file
          if (i == 0 && columns[0].trim() !== "Prénom" && columns[1].trim() !== "Nom" && columns[2].trim() !== "Email" && columns[3].trim() !== "Groupe"){ console.log(columns);
              this.csvIntegrityError = `Vous avez retiré la première ligne du fichier CSV que vous avez soumis, veuillez la remettre et soumettre à nouveau`;
              this.csvIntegrity = false;
              resolve();
              return;
          }

          // Check that the row has 4 columns
          if (columns.length !== 4) {
            this.csvIntegrityError = `Veuillez suivre le fichier schéma et ses 4 colonnes s'il vous plaît (il vous en manque au moins une).`;
            this.csvIntegrity = false;
            resolve();
            return;
          }

          // Check if the 4 columns are not empty
          if (columns[0].trim() === "") {
            this.csvIntegrityError = `Le champ 1 de la ligne ${i + 1} est vide.`;
            this.csvIntegrity = false;
            resolve();
            return;
          }

          if (columns[1].trim() === "") {
            this.csvIntegrityError = `Le champ 2 de la ligne ${i + 1} est vide.`;
            this.csvIntegrity = false;
            resolve();
            return;
          }

          if (columns[2].trim() === "") {
            this.csvIntegrityError = `Le champ 3 de la ligne ${i + 1} est vide.`;
            this.csvIntegrity = false;
            resolve();
            return;
          }

          if (columns[3].trim() === "") {
            this.csvIntegrityError = `Le champ 4 de la ligne ${i + 1} est vide.`;
            this.csvIntegrity = false;
            resolve();
            return;
          }
        }

        //Return true because all the tests are done and no one triggered.
        this.csvIntegrity = true;
        this.csvIntegrityError= ''; //To delete the text if there was a defined error text, destroying it so that it will no longer be displayed when the right csv is submited
        resolve();

      };
      reader.readAsText(file, "UTF-8");
    });
  }

  private parseCsvFile(file: File): Promise<void> {
    return new Promise((resolve, reject) => {
      const reader: FileReader = new FileReader();

      let self = this;
      reader.onload = function (event) {
        const text: string | undefined = event.target?.result?.toString();

        if (text) {
          resolve(self.csvToStudentArray(text));
        }
      };

      reader.onerror = reject;

      reader.readAsText(file, "UTF-8");
    });
  }

  private csvToStudentArray(text: string, delimiter: string = ",") {
    const headers: string[] = text.slice(0, text.indexOf("\r\n")).split(delimiter);
    const rows: string[] = text.slice(text.indexOf("\n") + 1).split("\r\n");

    // We remove the trailing empty row
    rows.pop();

    const students: Student[] = rows.map((row) => {
      const values: string[] = row.split(delimiter);

      const element: Student = headers.reduce((object, header, index) => {
        object.setPropertyFromHeader(header, values[index]);
        return object;
      }, new Student("", "", ""));

      return element;
    })

    const newStudentCount = this.userList.length + students.length;

    if (newStudentCount > this.maxStudents) {
      this.selectedStudentError = `Vous ne pouvez pas ajouter autant d'élèves, vous pouvez en ajouter ${this.maxStudents - this.userList.length}`;
      this.selectedStudentFile = undefined;
    } else {
      const hasDuplicateInFile = this.hasDuplicateInFile(students);
      if (hasDuplicateInFile.length > 0) {
        const numberDuplicates = hasDuplicateInFile.split(',').length;
        this.repeatedNameError = numberDuplicates === 1
          ? `Vous avez un élève en doublon dans votre fichier CSV : ${hasDuplicateInFile}`
          : `Vous avez ${numberDuplicates} élèves en doublon dans votre fichier CSV : ${hasDuplicateInFile}`
        this.selectedStudents = [];
        this.selectedStudentFile = undefined;
      } else {
        const hasDuplicateInclass = this.hasDuplicateInClass(students, this.userList);
        if (hasDuplicateInclass.length > 0) {
          const numberDuplicates = hasDuplicateInclass.split(',').length;
          this.repeatedNameError = numberDuplicates === 1
            ? `Vous avez un élève en doublon : ${hasDuplicateInclass} appartient déjà à votre classe`
            : `Vous avez ${numberDuplicates} élèves en doublon : ${hasDuplicateInclass} appartiennent déjà à votre classe`
          this.selectedStudents = [];
          this.selectedStudentFile = undefined;
        } else {
          this.selectedStudents = students;
          this.selectedStudentError = '';
          this.repeatedNameError = '';
        }
      }
    }
  }
  private hasDuplicateInFile(studentList: Student[]): string {
    const length = studentList.length;
    let duplicates: string = '';

    for (let i = 0; i < length - 1; i++) {
      const currentStudent = studentList[i];

      for (let j = i + 1; j < length; j++) {
        const compareStudent = studentList[j];

        if (
          currentStudent.firstName.toLowerCase() === compareStudent.firstName.toLowerCase() &&
          currentStudent.lastName.toLowerCase() === compareStudent.lastName.toLowerCase()
        ) {
          if (duplicates !== '') {
            duplicates += ', ';
          }
          duplicates += `${currentStudent.firstName}.${currentStudent.lastName}`; // has duplicate in the CSV file
        }
      }
    }
    return duplicates;
  }

  private hasDuplicateInClass(csvStudents: Student[], classStudents: User[]): string {
    const csvStudentsLength = csvStudents.length;
    const classStudentsLength = classStudents.length;
    let duplicates: string = '';

    for (let i = 0; i < csvStudentsLength; i++) {
      const currentStudent = csvStudents[i];
      let foundDuplicate = false;

      for (let j = 0; j < classStudentsLength; j++) {
        const compareStudent = classStudents[j];
        const currentStudentUsername = currentStudent.firstName + "." + currentStudent.lastName;
        if (currentStudentUsername.toLowerCase() === compareStudent.username.toLowerCase().replace(/\d/g, '')) {
          foundDuplicate = true;
          if (duplicates !== '') {
            duplicates += ', ';
          }
          duplicates += `${currentStudent.firstName}.${currentStudent.lastName}`;
        }
      }
      if (!foundDuplicate && duplicates !== '') {
        duplicates += ', ';
      }
    }
    return duplicates;
  }

  private convertStudentsToUser(studentList: Student[]): Promise<User[]> {
    return new Promise((resolve, reject) => {
      let userList: User[] = [];

      studentList.forEach((student) => {
        const username = student.firstName.toLowerCase() + "." + student.lastName.toLowerCase();
        this.apiService.getUniqueUsername(username).subscribe((uniqueUsername) => {
          let tempId: string;

          do {
            tempId = this.generatePassword(12);
          } while (this.userTempIds.indexOf(tempId) > -1)

          this.userTempIds.push(tempId);

          userList.push({
            _id: tempId,
            username: uniqueUsername,
            emails: [student.email],
            password: student.password ? student.password : this.generatePassword(8),
            groups: student.group ? [student.group] : [],
            role: ['learner'],
            created: true,
            deleted: false
          });

          if (userList.length === studentList.length) {
            resolve(userList);
          }
        }, (error) => {
          reject(error);
        });
      });

      if (studentList.length === 0) {
        resolve([]);
      }
    });
  }

  private generatePassword(passwordLength: number): string {
    const characterCollection = "0123456789abcdefghijklnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-_()[]!?";

    let password = "";

    for (let i = 0; i < passwordLength; i++) {
      password += characterCollection[Math.floor(Math.random() * characterCollection.length)];
    }

    return password;
  }

  public saveClass(): void {
    if (this.group === null) return;

    this.changedForm = false;
    this.changedStudents = false;
    this.changedGroups = false;

    let newUsers = this.userList.filter((user) => user.created && !user.deleted);
    let deleteUsers = this.userList.filter((user) => !user.created && user.deleted);
    let postGroups = this.groupList.filter((group: Group) => (group._id === '' || group.updated) && !group.deleted);
    let deleteGroups = this.groupList.filter((group: Group) => group.deleted).map((group: Group) => group._id as string);

    const usersCreateBody: UserCreateBody = {
      mailSender: '"Roots of Tomorrow" <noreply@roots-of-tomorrow.com>',
      mailSubject: 'Création de compte',
      mailBody: "Votre compte Roots of Tomorrow vient d'être créé, vous pouvez désormais vous connecter sur ce lien : https://rootsoftomorrow.gamabilis.com/ avec les identifiants suivants\n",
      mailSignature: "Restant à votre disposition\nL'équipe Gamabilis\n noreply@roots-of-tomorrow.com",
      users: newUsers
    };

    this.apiService.createUsers(usersCreateBody).subscribe((users: Record<string, any>[]) => {
      users.forEach((user) => {
        let foundUser = newUsers.find((newUser) => newUser.username === user.username);

        if (foundUser) {
          this.groupList.forEach((group) => {
            let members = group.members as string[];
            let index = members.indexOf((foundUser as User)._id);

            if (index > -1) {
              members[index] = user._id;
            }
          });

          foundUser._id = user._id;
        }
      });

      if (this.group) {
        this.group.name = this.classForm.controls['className'].value;
        this.group.members = this.userList.filter((user) => !user.deleted).map((user) => user._id);

        let doneGroups = [];

        postGroups.forEach((postGroup) => {
          if (postGroup._id === '') {
            this.apiService.postGroup(postGroup).subscribe((group: Record<string, any>) => {
              doneGroups.push(group);

              postGroup._id = group._id;
              postGroup.updated = false;

              if (doneGroups.length === postGroups.length) {
                this.updateGroup();
              }
            });
          } else if (postGroup.updated) {
            this.apiService.updateGroup(postGroup).subscribe((group: Record<string, any>) => {
              doneGroups.push(group);

              postGroup.updated = false;

              if (doneGroups.length === postGroups.length) {
                this.updateGroup();
              }
            });
          }
        });

        if (postGroups.length === 0) {
          this.updateGroup();
        }
      }
    });

    deleteUsers.forEach((user) => this.apiService.deleteMemberFromGroup(this.group!._id, user._id));
    deleteUsers.forEach((user) => this.apiService.deleteUser(user._id).subscribe(() => {}));

    deleteGroups.forEach((groupId) => this.apiService.deleteGroup(groupId).subscribe(() => {}));
  }

  public createGroup(): void {
    const group: Group = {
      _id: '',
      name: 'Groupe ' + (this.groupList.length + 1),
      members: [],
      deleted: false,
    };

    this.groupList.push(group);

    this.changedGroups = true;

    this.showSubgroupSelect(group);
  }

  public removeGroup(group: Group): void {
    group.deleted = true;

    this.changedGroups = this.haveGroupsChanged();
  }

  public restoreGroup(group: Group): void {
    group.deleted = false;

    this.changedGroups = this.haveGroupsChanged();
  }

  public showSubgroupSelect(group: Group): void {
    this.dialog.open(WorkgroupCreateComponent, {
      data: {
        group: group,
        userList: this.userList
      },
    }).afterClosed().subscribe(() => {
      this.changedGroups = this.haveGroupsChanged();
    });
  }

  private haveGroupsChanged(): boolean {
    this.listUsersGroup();
    let sourceGroupList = this.group?.groups as Group[];

    return this.groupList.some((group: Group) => {
      if (group._id !== '') {
        const sourceGroup = sourceGroupList.find((searchGroup) => searchGroup._id === group._id);

        if (sourceGroup) {
          let changed: boolean = false;

          changed = changed || sourceGroup.name !== group.name;
          changed = changed || sourceGroup.members.length !== group.members.length;
          changed = changed || (sourceGroup.members as string[]).some((member) => (group.members as string[]).indexOf(member) === -1);
          changed = changed || group.deleted;

          group.updated = changed;

          return changed;
        } else {
          group.updated = true;

          return true;
        }
      } else {
        return !group.deleted;
      }
    });
  }

  private updateGroup(): void {
    if (this.group) {
      this.group.groups = this.groupList.filter((group) => !group.deleted).map((group) => group._id as string);

      this.apiService.updateGroup(this.group).subscribe((group) => {
        this.loadGroup(group);
      });
    }
  }

  private loadGroup(group: any): void {
    this.group = group;

    this.classForm.setValue({
      className: this.group?.name,
      schoolYear: this.group?.attributes ? this.group?.attributes['schoolYear'] : ''
    });

    this.userList = this.group?.members as User[];
    this.groupList = _.cloneDeep(this.group?.groups as Group[]);

    this.listUsersGroup();

    this.changedForm = false;
    this.changedStudents = false;
    this.changedGroups = false;
    this.userTempIds = [];
  }

  private listUsersGroup(): void {
    this.userList.forEach((user: User) => user.groups = []);

    this.groupList.forEach((group: Group) => {
      (group.members as string[]).forEach((userId) => {
        let user = this.userList.find((user) => user._id === userId);

        if (user) {
          user.groups?.push(group.name);
        }
      });
    });
  }

  private createGroupsFromUsers(users: User[]): void {
    users.forEach((user: User) => {
      if (user.groups && user.groups.length > 0) {
        user.groups.forEach((groupName) => {
          let group = this.groupList.find((searchGroup) => searchGroup.name === groupName);

          if (group) {
            let members = group.members as string[];
            if (members.indexOf(user._id) === -1) {
              members.push(user._id);
            }
          } else {
            group = {
              _id: '',
              name: groupName,
              members: [user._id],
              deleted: false,
            };

            this.groupList.push(group);
          }
        });
      }
    });

    this.changedGroups = this.haveGroupsChanged();
  }

  public get maxStudents(): number {
    return this.group?.maxMembers as number;
  }

  public get changed(): boolean {
    return this.changedForm || this.changedStudents || this.changedGroups;
  }
}
