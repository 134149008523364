import {Component, HostListener, Inject, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {ApiService} from "../services/api.service";
import {Subscription} from "rxjs";
import {HttpClient, HttpEventType} from "@angular/common/http";
import {ProgressBarMode} from "@angular/material/progress-bar";
import {forEach} from "lodash";
import {DomSanitizer, SafeHtml, SafeResourceUrl} from '@angular/platform-browser';
import { Router } from '@angular/router';
import {RoleService} from "../services/role.service";


class SteamNews {
  title: string;
  titleNews : string;
  content: string;
  date: number;

  constructor(title: string, titleNews : string, content: string, date: number) {
    this.title = title;
    this.titleNews = titleNews;
    this.content = content;
    this.date = date;
  }
}

export interface DialogDownloadData{
  name: string;
  platform : string;
  login2 : HomeComponent;
  userIsConnected : boolean;
}

export interface DialogNewsData {
  stringHTMLNews : SafeHtml;
  stringNewsTitle : string;
  sRURLYt : SafeResourceUrl;
  urlYt : string;
}

export enum Progress {
  buffering,
  inProgress,
  error,
  done
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class HomeComponent {


  login2 : HomeComponent = this;
  name: string | undefined
  platform : string | undefined

  // Responsivity
  isMobileSize : boolean;
  mobileSize : number = 992;

  // Steam News
  urlNews = 'http://api.steampowered.com/ISteamNews/GetNewsForApp/v0002/?appid=1605430&count=3&maxlength=0&format=json&feeds=steam_community_announcements';
  stringNewsTitle : string;
  steamNewsCount : number = 9;
  stringHTMLNews : SafeHtml;
  stringNewsList : string = "";
  urlYT : string = "";
  trustedHtml : SafeHtml;
  urlImageSteam = "https://steamcdn-a.akamaihd.net/steamcommunity/public/images/clans";
  public arraySteamNews: SteamNews[] = [];
  public arrayURLImagesNews : string[] = [];
  numberMaxTextNews : number = 37;
  dicDays =  new Map<number, string>([
    [0,'lun'], [1,'mar'], [2,'mer'], [3,'jeu'], [4,'ven'], [5,'sam'], [6,'dim'],
  ]);
  dicMonths =  new Map<number, string>([
    [0,'janvier'], [1,'février'], [2,'mars'], [3,'avril'], [4,'mai'], [5,'juin'], [6,'juillet'],[7,'août'], [8,'septembre'], [9,'octobre'], [10,'novembre'], [11,'décembre'],
  ]);

  // Download Game
  public progress = Progress.done;
  public progressValue = 0;
  private downloadSubscription: Subscription | null = null;

  constructor(public dialog : MatDialog, public apiService: ApiService, public roleService : RoleService, private http : HttpClient, private sanitizer: DomSanitizer, private router: Router) {
  }

  openDialogDownload(text : string, platformDialog : string): void {
    this.dialog.open(HomeDialogComponent, {
      width: '30%',
      height: 'auto',
      data: {name: text, platform : platformDialog, login2 : this, userIsConnected : this.roleService.currentUser != null}, //Check if the user is connected
    });
  }
  openDialogNews(idNews : number): void {
    this.steamflowToHTML(idNews);
    this.dialog.open(HomeNewsDialogComponent, {
      width: '60%',
      height: 'auto',
      data: {stringHTMLNews : this.stringHTMLNews, stringNewsTitle : this.stringNewsTitle, sRURLYt : this.sanitizer.bypassSecurityTrustResourceUrl(this.urlYT), urlYt : this.urlYT},
    });
  }

  populateURLImagesNews(): void {
    let newArray = [];
    //Get the images from steam app news and store them in an url array
    this.apiService.postHTMLFromURL('https://store.steampowered.com/news/app/1605430').subscribe((page) => {
    });
  }

  @HostListener("window:resize", [])
  onResize() {
    var width = window.innerWidth;
    this.isMobileSize = width < this.mobileSize;
    console.log(this.isMobileSize);
  }

  ngOnInit(): void {
    this.apiService.getNewsGame(this.steamNewsCount).toPromise().then(data => {
      data.appnews.newsitems.forEach((object : any,key : number) => {
        //Create new string for button title cropping
        let titleStringCropped = new String(object.title);
        if(titleStringCropped.length >= this.numberMaxTextNews){
          titleStringCropped = titleStringCropped.substring(0,this.numberMaxTextNews) + "...";
        }
        this.arraySteamNews.push(new SteamNews(object.title, titleStringCropped.toString(),object.contents, object.date*1000)); //Multiply by 1000 to create milliseconds
      })
      this.trustedHtml = this.sanitizer.bypassSecurityTrustHtml(this.stringNewsList);
    })
  }

  ngOnClickAccess(role : string) : void {
    this.router.navigate(
      ['/login'],
      { queryParams: { role : role } }
    );
  }

  intToDate(dateNum : number) : string{
    let newDate = new Date(dateNum);
    let day: string | undefined = "";
    let month: string | undefined = "";

    day = this.dicDays.get(newDate.getDay());
    month = this.dicMonths.get(newDate.getMonth());
    return day + ". " + newDate.getDate() + " " + month + " " + newDate.getFullYear() ;
  }

  // Transform the text we get from steam to html text
  steamflowToHTML(idNews : number) : void {
    // Attribuate title from what we got with the API and the ID
    this.stringNewsTitle = this.arraySteamNews[idNews].title;
    let steamText = this.arraySteamNews[idNews].content;

    let inBracket = false;
    let stringInBracket = '';
    let htmlNews = this.stringHTMLNews = "";
    let urlYTHtml = "";
    let wordLetterToSkip = 0;
    let ref = this;

    // URL Management
    let inURL = false;
    let inURLLink = "";
    let inURLHtml = "";
    let endBracket = false;

    let arrayStringToDelete : string[] = [];

    // Delete opening and closing ' " '
    forEach(steamText, function (value, key){
      if(wordLetterToSkip == 0){
        if(value == '['){
          inBracket = true;
        }else if (value == ']'){
          inBracket = false
          // The end of [url']' need to be avoided by the check behind
          endBracket = true;
        }

        // Outside Bracket - Text
        if(!inBracket){
          if(value != ']'){
            if(inURL){
              inURLHtml += value;
            }else{
              htmlNews += value;
            }
          }else{
            // In URL Management
            if(endBracket){
              endBracket = false;
            }
          }
        }
        else{
          // Inside Bracket - HTML switch
          let i = 1;
          stringInBracket = '';
          while (steamText.charAt(key+i) != ']'){
            stringInBracket += (steamText.charAt(key+i));
            i++;
            wordLetterToSkip++;
          }

          let endUrlYB = '';
          forEach(stringInBracket, function (value, key){
            // Check '='
            if(value == ';' && stringInBracket.match("previewyoutube")){
              stringInBracket = stringInBracket.substring(0,key);
              // Get the youtube URL from string
              endUrlYB = stringInBracket.substring(stringInBracket.length-11);
              stringInBracket = stringInBracket.substring(0,stringInBracket.length-12);
            }
            // Check 'url'
            if(value == '=' && stringInBracket.match("url")){
              inURL = true;
              inURLLink = stringInBracket.substring(4);
            }
          });

          let htmlText = '';
          switch (stringInBracket){
            case 'u':
              htmlText = '<u>'
              break;
            case '/u' :
              htmlText = '</u>'
              break;
            case 'b' :
              htmlText = '<b>'
              break;
            case '/b' :
              htmlText = '</b>'
              break;
            case "list" :
              htmlText = '<ul>'
              break;
            case "/list" :
              htmlText = '</ul>'
              break;
            case "*" :
              htmlText = '<li>'
              break;
            case "previewyoutube" :
              // This section is not working properly and need to be plugged differently in the HTML (see login2-news-dialog html)
              let urlYT = "https://www.youtube.com/embed/" + endUrlYB;
              if(urlYTHtml != null){
                urlYTHtml = urlYT;
              }
              htmlText = "";
              break;
            case "img" :
              let urlImg = steamText.substring(key+5,key + 77);
              arrayStringToDelete.push(urlImg);
              urlImg = urlImg.replace("{STEAM_CLAN_IMAGE}",ref.urlImageSteam);
              if(inURL){
                // If there is no https, add '//' to the link, otherwise it will link toward localhost
                if(!inURLLink.match("https")){
                  inURLLink = '//' + inURLLink;
                }
                htmlText = '<br></be><a href="' + inURLLink + '" target="_blank"><img class="news-image" src="' + urlImg + '"/></a><br>';
                inURL = false;
              }else{
                htmlText = '<br><img class="news-image" target="_blank" src="' + urlImg + '"/><br>';
              }
              break
            case "/url" :
              // Display the stocked HTML in href
              if(inURLHtml != ""){
                htmlText = '<a href="' + inURLLink + '" target="_blank">' + inURLHtml + '</a>';
                inURLHtml = "";
                inURLLink = "";
                inURL = false;
              }
              break
            default:
              // <h>
              if(stringInBracket.charAt(0) == 'h'){
                htmlText = '<p><h' + stringInBracket.charAt(1) +' class="side-text" >';
              }
              if(stringInBracket.charAt(0) == '/' && stringInBracket.charAt(1) == 'h'){
                htmlText = '</h' + stringInBracket.charAt(2) +'></p>';
              }
              break
          }
          htmlNews += htmlText;
        }
      }else{
        wordLetterToSkip--;
      }
    });

    // Replace all \n by html <br> (Go to line)
    for (let i = (htmlNews.match(/[\n]/g) || []).length; i > 0; i--) {
      htmlNews = htmlNews.replace("\n\n", "<br>");
    }

    // Delete some text already filtered
    if(arrayStringToDelete.length > 0){
      forEach(arrayStringToDelete, function(value, key){
        htmlNews = htmlNews.replace(arrayStringToDelete[key],"");
      });
      arrayStringToDelete = [];
    }

    this.urlYT = urlYTHtml;
    this.stringHTMLNews = this.sanitizer.bypassSecurityTrustHtml(htmlNews);
  }

  ifArrayIsFull() : boolean{
    return (this.arraySteamNews.length >= this.steamNewsCount);
  }
  OnClickGoLink(url : string) : void{
    window.open(url, "_blank");
  }

  //return column display depending on the screen size
  getNewsColMobile() : string {
    return this.isMobileSize ? "2" : "3";
  }

  // Download
  ngOnDestroy(): void {
    if (this.downloadSubscription !== null) {
      this.downloadSubscription.unsubscribe();
    }
  }

  public downloadGame(platform: string): void {
    if (!this.canDownload) {
      console.log("Can Download : " + this.canDownload);
      return;
    }

    // Check if user is connected
    if(this.roleService.currentUser == null){
      return;
    }

    this.downloadSubscription = this.apiService.downloadGame(platform).subscribe((data) => {
      if (data.type === HttpEventType.DownloadProgress) {
        const percentDone = Math.round(100 * data.loaded / data.total);
        this.updateDownloadProgressState(platform, Progress.inProgress);
        this.updateDownloadProgressValue(platform, percentDone);
      } else if (data.type === HttpEventType.Response) {
        const blob = new Blob([data.body], {type: "application/zip"});

        const downloadUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = "Roots of Tomorrow_" + platform + ".zip";
        link.click();
        URL.revokeObjectURL(downloadUrl);
        this.downloadSubscription = null;
        this.updateDownloadProgressState(platform, Progress.done);
      } else {
        this.updateDownloadProgressState(platform, Progress.buffering);
      }
    }, (error) => {
      console.log("Download failed", error);
      this.updateDownloadProgressState(platform, Progress.error);
    });
  }

  public get canDownload(): boolean {
    return this.progress === Progress.error || this.progress === Progress.done;
  }

  public get progressMode(): ProgressBarMode {
    return this.progress === Progress.inProgress ? 'determinate' : 'buffer';
  }

  private updateDownloadProgressState(platform: string, newProgress: Progress): void {
    this.progress = newProgress;
  }

  private updateDownloadProgressValue(platform: string, value: number): void {
    console.log("Downloading value : " +value);
    this.progressValue = value;
  }
}

@Component({
  selector: 'app-login-2dialog',
  templateUrl: 'home-dialog.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeDialogComponent {
  constructor(
    public dialogRef: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogDownloadData,) {}

  onDownloadClick(): void {
    this.data.login2.downloadGame(this.data.platform);
    this.dialogRef.closeAll();
  }

  onReturnClick(): void {
    this.dialogRef.closeAll();
  }
}

@Component({
  selector: 'app-home-news-dialog',
  templateUrl: 'home-news-dialog.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class HomeNewsDialogComponent {
  constructor(
    public dialogRef: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogNewsData,) {}

  onNoClick(): void {
    this.dialogRef.closeAll();
  }
}
