<form [formGroup]="groupForm" (ngSubmit)="saveGroup()">
    <span class="vertical-align">
        <h1>Gérer ce groupe</h1>
        <mat-form-field>
            <mat-label>Nom du groupe</mat-label>
            <input matInput type="text" formControlName="groupName">
        </mat-form-field>
        <div class="list-container">
            <mat-checkbox *ngFor="let user of userList" [checked]="isUserSelected(user._id)" (change)="selectUser($event)" [value]="user._id">{{ user.username }}</mat-checkbox>
        </div>
        
        <div class="horizontal-align centered">
            <button mat-raised-button color="accent">
                Enregistrer
            </button>
        </div>
    </span>
</form>