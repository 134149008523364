import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { TokenService } from './token.service';
import { EnvironmentService } from './environment.service';
import { ApiService } from './api.service';
import { RoleService } from './role.service';

const OAUTH_CLIENT = 'api';
const OAUTH_SECRET = 'secret';

const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: 'Basic ' + btoa(OAUTH_CLIENT + ':' + OAUTH_SECRET)
  })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public redirectUrl = '';

  private static handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`, error.error);
    }
    return throwError({status: error.status, message: 'Something bad happened; please try again later.'});
  }

  private static log(message: string): any {
    console.log(message);
  }

  constructor(private http: HttpClient, private tokenService: TokenService, private environmentService: EnvironmentService, private apiService: ApiService,
    private roleService: RoleService) { }

  public login(loginData: any): Observable<any> {
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();
    const body = new HttpParams()
      .set('username', loginData.username)
      .set('password', loginData.password)
      .set('grant_type', 'password');

    return this.http.post<any>(this.environmentService.apiUrl + '/access_token', body, HTTP_OPTIONS)
      .pipe(
        tap(res => {
          this.tokenService.saveToken(res.access_token);
          this.tokenService.saveRefreshToken(res.refresh_token);

          this.roleService.fetchUserInfos(null);
        }),
        catchError(AuthService.handleError)
      );
  }

  public baseRegister(registerData: any): Observable<any> {
    const body = new HttpParams()
      .set('username', registerData.username)
      .set('emails', registerData.email)
      .set('password', registerData.password)
      .set('confirmPassword', registerData.confirmPassword)
      .set('requestTrainerRole', registerData.requestTrainerRole)
      .set('requestManagerRole', registerData.requestManagerRole);

    return this.http.post<any>(this.environmentService.apiUrl + '/new/user', body, HTTP_OPTIONS).pipe(catchError(AuthService.handleError));
  }

  public refreshToken(refreshData: any): Observable<any> {
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();
    const body = new HttpParams()
      .set('refresh_token', refreshData.refresh_token)
      .set('grant_type', 'refresh_token');
    return this.http.post<any>(this.environmentService.apiUrl + '/access_token', body, HTTP_OPTIONS)
      .pipe(
        tap(res => {
          this.tokenService.saveToken(res.access_token);
          this.tokenService.saveRefreshToken(res.refresh_token);
        }),
        catchError(AuthService.handleError)
      );
  }

  public recover(recoverData: any): Observable<any> {
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();

    const body = new HttpParams()
      .set('username', recoverData.username)
      .set('url', recoverData.url);

    return this.http.post<any>(this.environmentService.apiUrl + '/password/recover', body, HTTP_OPTIONS).pipe(catchError(AuthService.handleError));
  }

  public reset(resetData: any): Observable<any> {
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();

    const body = new HttpParams()
      .set('resetId', resetData.resetId)
      .set('password', resetData.password)
      .set('confirmPassword', resetData.confirmPassword);

    return this.http.post<any>(this.environmentService.apiUrl + '/password/reset', body, HTTP_OPTIONS).pipe(catchError(AuthService.handleError));
  }

  public logout(): void {
    this.roleService.userRoles = ["NOT_CONNECTED"];
    this.roleService.currentUser = null;
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();
  }
}
