import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public usernameForm = new FormGroup({
    username: new FormControl('', Validators.required),
    url: new FormControl(''),
  });
  public resetForm: FormGroup;
  public recoverError = '';
  public recoverMessage = '';

  private resetId: string | null = null;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
  ) {
    this.resetForm = formBuilder.group({
      username: [''],
      resetId: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(6)]], // TODO ajouter des contraintes plus fortes sur le mot de passe
      confirmPassword: ['', Validators.required],
    }, {validators: this.matchPassword})
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      this.resetId = paramMap.get('resetId');

      if (this.resetId !== null) {
        this.resetForm.controls['resetId'].setValue(this.resetId);
      }

      this.usernameForm.controls['url'].setValue(window.location.href);
    });
  }

  public isResetting(): boolean {
    return this.resetId !== null;
  }

  public hasSentRecover(): boolean {
    return this.recoverMessage !== '';
  }

  public getUsernameErrorMessage() {
    if (this.usernameForm.controls['username'].hasError('required')) {
      return 'Vous devez entrer un nom d\'utilisateur';
    }

    return '';
  }

  public getPasswordErrorMessage() {
    if (this.resetForm.controls['password'].hasError('required')) {
      return 'Vous devez entrer un mot de passe';
    }

    return this.resetForm.controls['password'].hasError('minlength') ? 'Le mot de passe doit contenir au moins 6 caractères' : '';
  }

  public getConfirmPasswordErrorMessage() {
    if (this.resetForm.controls['confirmPassword'].hasError('required')) {
      return 'Vous devez entrer un mot de passe';
    }

    return '';
  }

  public submitRecover() {
    this.authService.recover(this.usernameForm.value).subscribe(() => {
      this.recoverMessage = 'Un email a été envoyé à l\'adresse associée au compte correspondant au nom d\'utilisateur renseigné. Veuillez suivre les instructions fournies dans cet email pour réinitialiser votre mot de passe.'
    }, (error: any) => {
      this.recoverError = 'Erreur lors de la récupération de mot de passe';
    });
  }

  public submitReset() {
    this.authService.reset(this.resetForm.value).subscribe((user: any) => {
      this.resetForm.controls['username'].setValue(user['username']);
      this.authService.login(this.resetForm.value).subscribe(() => {
        this.recoverError = '';
        this.router.navigate([this.authService.redirectUrl]);
      }, (error: any) => {
        if (error.status === 503) {
          this.recoverError = 'Mauvais nom d\'utilisateur et/ou mot de passe';
        } else {
          this.recoverError = 'Erreur lors de la connexion';
        }
      });
    }, (error: any) => {
      this.recoverError = 'Erreur lors de la réinitilisation du mot de passe';
    });
  }

  private matchPassword(control: AbstractControl): ValidationErrors | null {
    const password = control.get("password")?.value;
    const confirm = control.get("confirmPassword")?.value;
 
    if (password != confirm) {
      return { 'noMatch': true };
    }
 
    return null;
  }
}
