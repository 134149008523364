import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {
  public displayText = '';

  public confirmButtonText = '';
  public confirmButtonColor = 'accent';
  
  public cancelButtonText = '';
  public cancelButtonColor = 'warn';

  constructor(
    private dialogRef: MatDialogRef<PopupComponent>,
    @Inject(MAT_DIALOG_DATA) private data: Record<string, any>,
  ) { }

  ngOnInit(): void {
    this.displayText = this.data.text;
    this.confirmButtonText = this.data.confirm;
    this.cancelButtonText = this.data.cancel;

    if (this.data.confirmColor) {
      this.confirmButtonColor = this.data.confirmColor;
    }
    if (this.data.cancelColor) {
      this.cancelButtonColor = this.data.cancelColor;
    }
  }

  public confirm(): void {
    this.dialogRef.close(true);
  }

  public cancel(): void {
    this.dialogRef.close();
  }
}
