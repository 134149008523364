import { License } from './../services/role.service';
import { Component, AfterViewInit, ViewChild, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ClassCreateComponent } from '../class/class-create/class-create.component';
import { ApiService } from '../services/api.service';
import { RoleService } from '../services/role.service';

export interface Group {
  _id: string,
  name: string,
  members: string[],
  owners: string[]
}

@Component({
  selector: 'app-classes-list',
  templateUrl: './classes-list.component.html',
  styleUrls: ['./classes-list.component.scss']
})
export class ClassesListComponent implements AfterViewInit, OnInit {
  public classesList: MatTableDataSource<Group> = new MatTableDataSource();

  public tableColumns = ['name', 'schoolYear', 'members'];

  public creditsCount = 0;
  private availableLicense: License|null = null;

  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private apiService: ApiService,
    public roleService: RoleService,
    private router: Router,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    // Get the number of remaining credits the user has
    this.getRemainingCredits();
  }

  ngAfterViewInit(): void {
    this.apiService.getGroupsFromUserOwner().subscribe((groups: Group[]) => {
      this.classesList = new MatTableDataSource(groups);
      this.classesList.sort = this.sort;
      console.log("NgAfterViewInit " + this.classesList.data.length);
    }, (err: any) => {
      console.log('Error : ' + err);
    });
  }

  public editClass(group: Group): void {
    this.router.navigate(['/class', group._id]);
  }

  public goToClassCreation() {
    this.dialog.open(ClassCreateComponent, {
      data: {
        license: this.availableLicense,
      },
    })
  }

  private async getRemainingCredits() {
    this.roleService.fetchUserInfos((user) => {
      const licenseList = user.licenseList;

      if (licenseList) {
        licenseList.forEach(license => {
          if (license.group === null) {
            this.creditsCount += 1;

            if (this.availableLicense === null) {
              this.availableLicense = license;
            }
          }
        });
      }
    },true);
  }
}
