import { NavigationService } from './navigation.service';
import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  constructor(
    private navigationService: NavigationService,
    public roleService: RoleService,
    private router: Router
  ) {
    router.events.pipe(
      filter((e: Event): e is NavigationEnd => e instanceof NavigationEnd)
    ).subscribe((e: NavigationEnd) => {
      navigationService.closeSidenav();
    });
  }

  ngOnInit(): void {
  }

  public get isOpen() {
    return this.navigationService.SidenavOpened;
  }

  // TODO export into a service
  public isUrlPublic(): boolean {
    const publicUrlList = ['/login', '/register', '/reset-password', '/home'];

    for (let i = 0; i < publicUrlList.length; i++) {
      if (this.router.url.indexOf(publicUrlList[i]) !== -1) {
        return true;
      }
    }

    return false;
  }
}
