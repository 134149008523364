<div class="container">
    <img class="side-image" src="/assets/images/Connexion_Background.png" />
    
</div>
<div class="login-type-text">
    <h1>{{ getLoginTypeTitle() }}</h1>
    <h2>{{ getLoginTypeSubtitle() }}</h2>
</div>
<div class="side-login">
    <div fxLayout fxFill fxLayoutAlign="space-around center">
        <div class="vertical">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>
                        <mat-icon class="material-icons-outlined">account_circle</mat-icon>
                        <div>Connexion</div>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <form class="vertical" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                        <mat-form-field appearance="fill">
                            <mat-label>Nom d'utilisateur</mat-label>
                            <input matInput formControlName="username" required>
                            <mat-error *ngIf="loginForm.controls['username'].invalid">{{getUsernameErrorMessage()}}</mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>Mot de passe</mat-label>
                            <div class="horizontal">
                                <input matInput [type]="showPassword ? 'text' : 'password'" formControlName="password" required>
                                <mat-icon (click)="togglePasswordVisibility()">{{showPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
                            </div>
                            <mat-error *ngIf="loginForm.controls['password'].invalid">{{getPasswordErrorMessage()}}</mat-error>
                        </mat-form-field>
                        <div class="horizontal">
                            <mat-checkbox (change)="rememberMeValue($event)">Se souvenir de moi</mat-checkbox>
                            <a routerLink="/reset-password">Mot de passe oublié ?</a>
                        </div>
                        <div class="horizontal">
                            <button mat-raised-button color="accent" type="submit" [disabled]="!loginForm.valid">Se connecter</button>
                        </div>
                        <p style="color: red;" *ngIf="connectionError !== ''">{{connectionError}}</p>
                    </form>
                </mat-card-content>
            </mat-card>
            <a routerLink="/register" [queryParams]="{role: loginType}">Vous n'avez pas de compte ?</a>
        </div>
    </div>
</div>
