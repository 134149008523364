<form [formGroup]="gameForm" (ngSubmit)="postGame()" *ngIf="!isGamePosted">
    <mat-card name="cadre">
        <h2>Définis le cadre de ta partie</h2>

        <span class="horizontal-align" style="margin-bottom: 10px;">
            <p class="text-vertical-align">Durée des tours (semaines) : </p>
            <p class="text-vertical-align">{{ turnSliderValue }} </p>
            <mat-slider
                [min]="2"
                [max]="12"
                [step]="2"
                [thumbLabel]="true"
                [(ngModel)]="turnSliderValue"
                [ngModelOptions]="{standalone: true}">
            </mat-slider>
        </span>

        <span class="horizontal-align">
            <p class="text-vertical-align">Scénario de départ : </p>
            <mat-form-field appearance="fill">
                <mat-label>Sélectionnez un scénario</mat-label>
                <mat-select formControlName="scenario" (selectionChange)="getIdValuePairArray($event.value)">
                    <mat-option *ngFor="let scenario of scenarios" [value]="scenario.id">
                        {{ scenario.value }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </span>
    </mat-card>

    <mat-card name="objectives">
        <h2>Définis les objectifs à atteindre en fin de partie (10 ans)</h2>

        <span class="horizontal-align">
            <span class="vertical-align" style="justify-content: space-between; margin-right: 20px;">
                <h3>Notes de départ :</h3>

                <p>Environnemental : 1,5/5</p>
                <p>Économique : 1,5/5</p>
                <p>Social : 1,5/5</p>
                <p>Global : 1,5/5</p>
            </span>
            <span>
                <h3>Fin de partie :</h3>
                <span class="vertical-align">
                    <span class="horizontal-align">
                        <p class="text-vertical-align text-horizontal-align">{{ environmentSliderValue }}</p>
                        <mat-slider
                            [min]="0"
                            [max]="5"
                            [step]="0.1"
                            [thumbLabel]="true"
                            [(ngModel)]="environmentSliderValue"
                            [ngModelOptions]="{standalone: true}">
                        </mat-slider>
                    </span>
                    <span class="horizontal-align">
                        <p class="text-vertical-align text-horizontal-align">{{ economicSliderValue }}</p>
                        <mat-slider
                            [min]="0"
                            [max]="5"
                            [step]="0.1"
                            [thumbLabel]="true"
                            [(ngModel)]="economicSliderValue"
                            [ngModelOptions]="{standalone: true}">
                        </mat-slider>
                    </span>
                    <span class="horizontal-align">
                        <p class="text-vertical-align text-horizontal-align">{{ socialSliderValue }}</p>
                        <mat-slider
                            [min]="0"
                            [max]="5"
                            [step]="0.1"
                            [thumbLabel]="true"
                            [(ngModel)]="socialSliderValue"
                            [ngModelOptions]="{standalone: true}">
                        </mat-slider>
                    </span>
                    <span class="horizontal-align">
                        <p class="text-vertical-align text-horizontal-align">{{ globalSliderValue }}</p>
                        <mat-slider
                            [min]="0"
                            [max]="5"
                            [step]="0.1"
                            [thumbLabel]="true"
                            [(ngModel)]="globalSliderValue"
                            [ngModelOptions]="{standalone: true}">
                        </mat-slider>
                    </span>
                </span>
            </span>
            <mat-divider [vertical]="true"></mat-divider>
            <span>
                <h3>Recherche à atteindre :</h3>
                <mat-form-field appearance="fill">
                    <mat-label>Sélectionnez une recherche</mat-label>
                    <mat-select formControlName="research">
                      <mat-option [value]="-1"></mat-option>
                      <mat-option *ngFor="let research of currentResearchs" [value]="research.id">
                          {{ research.value }}
                      </mat-option>
                    </mat-select>
                </mat-form-field>

                <h3>Objectif à atteindre :</h3>
                <mat-form-field appearance="fill">
                    <mat-label>Sélectionnez un objectif</mat-label>
                    <mat-select formControlName="objective">
                        <mat-option [value]="-1"></mat-option>
                        <mat-option *ngFor="let objective of currentObjectives" [value]="objective.id">
                            {{ objective.value }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </span>
        </span>
    </mat-card>

    <mat-card name="finalize">
        <h2>Finalise ta partie</h2>

        <span class="horizontal-align">
            <p class="text-vertical-align">Date de début de la partie :</p>
            <mat-form-field appearance="fill">
                <mat-label>Choisissez une date</mat-label>
                <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="startPicker" formControlName="startDate">
                <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                <mat-datepicker #startPicker></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <input matInput type="time" formControlName="startTime">
            </mat-form-field>
        </span>

      <span class="horizontal-align">
            <p class="text-vertical-align">Date de fin de la partie :</p>
            <mat-form-field appearance="fill">
                <mat-label>Choisissez une date</mat-label>
                <input matInput [min]="getMinimumDateForEnd()" [max]="maxDate" [matDatepicker]="endPicker" formControlName="endDate">
                <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                <mat-datepicker #endPicker></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <input matInput type="time" formControlName="endTime">
            </mat-form-field>
        </span>

        <span class="horizontal-align" *ngIf="roleService.isRoleAllowed(['trainer'])">
            <p class="text-vertical-align">Classe :</p>
            <mat-form-field appearance="fill">
                <mat-label>Sélectionnez une classe</mat-label>
                <mat-select formControlName="class" ng-required="!roleService.isRoleAllowed(['trainer'])">
                    <mat-option *ngFor="let group of groups" [value]="group._id">
                        {{ group.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </span>

        <span class="horizontal-align" *ngIf="roleService.isRoleAllowed(['manager'])">
            <p class="text-vertical-align">Nombre participants :</p>
            <mat-form-field  *ngIf="isGamePlayerLimited">
                <input matInput formControlName="gameMaxPlayers" type="number" min="1" max="1000">
            </mat-form-field>
            <h1 class="text-vertical-align" *ngIf="!isGamePlayerLimited">&#8734;</h1>
            <button mat-raised-button color="accent" style="margin: auto auto auto 10px;" *ngIf="isGamePlayerLimited" (click)="togglePlayerLimit(false)">Pas de limite</button>
            <button mat-raised-button color="accent" style="margin: auto auto auto 10px;" *ngIf="!isGamePlayerLimited" (click)="togglePlayerLimit(true)">Ajouter une limite</button>
        </span>

        <span class="horizontal-align">
            <p class="text-vertical-align">Nom de la partie :</p>
            <mat-form-field>
                <mat-label>Nom</mat-label>
                <input matInput placeholder="Nom" formControlName="gameName">
            </mat-form-field>
        </span>

        <span class="horizontal-align" *ngIf="roleService.isRoleAllowed(['manager'])">
            <p class="text-vertical-align">Partie :</p>
            <mat-button-toggle-group name="visibility" formControlName="visibility">
                <mat-button-toggle value="public">Publique</mat-button-toggle>
                <mat-button-toggle value="private">Privée</mat-button-toggle>
            </mat-button-toggle-group>
        </span>
    </mat-card>

    <button
        mat-raised-button
        color="accent"
        class="horizontal-align"
        style="margin: auto auto 10px auto;"
        type="submit"
        [disabled]="!gameForm.valid">
        Créer partie
    </button>
</form>

<mat-card name="game-code" *ngIf="isGamePosted && roleService.isRoleAllowed(['manager'])">
    <span class="vertical-align">
        <h2 class="text-horizontal-align">Partie créée ! Elle est maintenant accessible dans la liste des tournois.</h2>
        <p class="text-horizontal-align" *ngIf="!isGamePrivate">Elle est publique et donc accessible à tout le monde.</p>
        <p class="text-horizontal-align" *ngIf="isGamePrivate">Votre partie est privée et vous devez donc partager ce code à vos joueurs :</p>
        <mat-card class="vertical-align content-copy" matTooltip="Copier vers le presse-papier" [cdkCopyToClipboard]="gameCode" (click)="notifyCopy()" *ngIf="isGamePrivate">
            <span class="text-horizontal-align">{{ gameCode }}</span>
            <mat-icon>content_copy</mat-icon>
        </mat-card>
        <button mat-raised-button color="accent" class="horizontal-align" routerLink="/games-list">Retour à l'accueil</button>
    </span>
</mat-card>

<mat-card name="game-code" *ngIf="isGamePosted && roleService.isRoleAllowed(['trainer'])">
    <span class="vertical-align">
        <h2 class="text-horizontal-align">Partie créée ! Chaque élève de votre classe la retrouvera dans ses parties en cours.</h2>
        <p class="text-horizontal-align">Vous pouvez leur envoyer un mail automatiquement pour leur expliquer comment accéder au jeu :</p>

          <mat-card>
            <button class="horizontal-align" mat-raised-button color="accent" (click)="sendMailToClassGame()">
                <h1 style="margin-top: 10px; margin-bottom: 10px" >Envoyer un mail</h1>
            </button>
          </mat-card>

        <button mat-raised-button color="accent" class="horizontal-align" routerLink="/games-list">Retour à l'accueil</button>
    </span>
</mat-card>
