<span class="vertical-align">
    <h1>Créez une nouvelle classe</h1>
    <form [formGroup]="classForm" (ngSubmit)="createClass()">
        <span class="horizontal-align">
            <mat-form-field>
                <mat-label>Nom de la classe</mat-label>
                <input matInput type="text" placeholder="Entrez un nom" formControlName="className">
            </mat-form-field>

            <mat-form-field>
                <mat-label>Année scolaire</mat-label>
                <mat-select formControlName="schoolYear">
                    <mat-option *ngFor="let schoolYear of schoolYearList" [value]="schoolYear">
                        {{ schoolYear }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </span>

        <span class="horizontal-align centered">
            <button mat-raised-button color="accent" [disabled]="!classForm.valid">Enregistrer</button>
        </span>
    </form>
</span>